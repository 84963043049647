@import "var.conf";
@import "base.css";
@import "common.css";




















/*---------------------------------
Page CSS 2017.07
CSS 担当者名 Document 2017.07
Last Update 2017.07 担当者名
---------------------------------*/


/*
  404
-------------------------------------*/

#Page404 #Main {
    #ContBox01 {
        #ContSubBox01 {}
        #ContSubBox02 {}
    }
}




















/*
トップページ共通
  -------------------------------------*/

#Page.pageIndex,
#PageShiga.pageIndex {
    #MainImgInner {
        height: 540px;
        padding-top: 133px;
        padding-left: 19px;
        h2 {
            width: 390px;
            color: #065d91;
            font-size: 28px;
            line-height: 44px;
            padding-left: 0;
            font-weight: bold;
            span:nth-of-type(1) {
                position: relative;
                top: -8px;
                font-size: 16px;
                line-height:1.0;
            }
            span:nth-of-type(2) {
                letter-spacing: -5px;
            }
            span:nth-of-type(3) {
                border-top: 1px solid #9abdd2;
                display: block;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0.5px;
                line-height: 1;
                margin-top: 11px;
                padding-top: 16px;
                white-space: nowrap;
            }
        }
    }
    #MainImg+#Container #Main {
        margin-top: 81px;
        #ContBox01 {
            h3.h4 {
                color: #111;
                background: rgba(0, 0, 0, 0) url("../img/contents/index_icon01.png") no-repeat scroll left top / 28px 24px;
                span {
                    padding-left: 39px;
                }
            }
        }
        #ContBox02 {
            margin-top: 5px;
            padding-top: 40px;
            padding-bottom: 52px;
            background-color: #fff;
            border-top: none;
            div>span.largeBox {
                &:hover {
                    span {
                        background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 10px center / 26px 9px;
                    }
                }
                span {
                    background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 14px center / 26px 9px;
                    height: 40px;
                    padding: 0 0 0 15px;
                    position: absolute;
                    top: 140px;
                    width: 140px;
                    writing-mode: horizontal-tb;
                }
            }
        }
        #ContBox03,
        #ContBox04 {
            background-color: #f3f3f3;
            border-top: 4px solid #235471;
            margin-top: 20px;
            padding-bottom: 79px;
            padding-top: 60px;
            div.innerBasic>div:nth-of-type(1) {
                background: url("../img/contents/index_img08.jpg") no-repeat scroll right center;
                background-size: 171px 171px;
                height: 171px;
                overflow: hidden;
                margin-bottom: 38px;
                h4 {
                    float: left;
                    width: 220px;
                    font-size: 22px;
                    color: #111111;
                    padding-top: 18px;
                    span {
                        padding-left: 39px;
                    }
                    &+div {
                        float: left;
                        width: 580px;
                        padding-top: 18px;
                    }
                }
            }
            p.contactBack {
                margin-top: 14px;
                width: 215px;
            }
            div.divSemiSapo {
                dl {
                    width: 100%;
                    display: block;
                    margin-bottom: 4px;
                    background-color: #fff;
                    height: 76px;
                    line-height: 76px;
                    background: url("../img/contents/index_icon03.png") no-repeat scroll right 19px center;
                    background-size: 12px 21px;
                    transform: perspective(1px) translateZ(0px);
                    transition-duration: 0.3s;
                    transition-property: color, background-color, background, opacity;
                    &:hover {
                        background-color: #fff;
                        opacity: .8;
                        background: url("../img/contents/index_icon03.png") no-repeat scroll right 15px center;
                        a {
                            text-decoration: underline;
                            opacity: 1;
                        }
                    }
                    &:after {
                        clear: both;
                        content: "";
                        display: block;
                    }
                    dt {
                        float: left;
                        width: 121px;
                        text-align: center;
                        font-size: 14px;
                        color: #d8a668;
                        font-family: 'Open Sans', sans-serif;
                        font-weight: 600;
                        font-style: italic;
                    }
                    dd {
                        width: 810px;
                        float: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        letter-spacing: -1px;
                        a {
                            font-size: 18px;
                            color: #064c76;
                            font-weight: bold;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        #ContBox03 {
            h4 {
                background: url("../img/contents/index_icon02.png") no-repeat scroll left top 18px;
                background-size: 28px 26px;
            }
        }
        #ContBox04 {
            margin-top: 0;
            div.innerBasic>div:nth-of-type(1) {
                background: none;
                height: auto;
                margin-bottom: 0;
            }
            h4 {
                background: url("../img/contents/achievement_icon01.png") no-repeat scroll left top 16px;
                background-size: 33px 25px;
            }
            p.contactBack {
                margin-top: 0;
                width: 400px;
            }
        }
        #ContBox05 {
            background: url("../img/contents/index_bg.png") repeat scroll center top;
            background-size: 2560px 534px;
            border-top: 4px solid #235471;
            padding-top: 80px;
            padding-bottom: 83px;
            div.innerBasic {
                background: url("../img/contents/index_img09.png") no-repeat scroll left top 66px;
                background-size: 485px 302px;
                h4 {
                    font-size: 26px;
                    color: #fff;
                    text-align: center;
                    &:after {
                        display: none;
                    }
                    &+div {
                        margin-top: 39px;
                        padding-top: 40px;
                        padding-bottom: 26px;
                        padding-left: 515px;
                        background: url("../img/contents/index_icon05.png") no-repeat scroll left 515px top 5px, rgba(0, 0, 0, 0) url("../img/contents/index_icon06.png") no-repeat scroll right bottom;
                        p {
                            font-size: 18px;
                            line-height: 33px;
                            color: #fff;
                        }
                    }
                }
            }
            p.contactBack {
                margin-top: 50px;
                background-color: #fff;
                width: 205px;
                height: 50px;
                border: 1px solid #289772;
                a:hover {
                    border: 1px solid #fff;
                    line-height: 49px;
                }
            }
        }
    }
}
































/*
  トップページ（滋賀用）
  -------------------------------------*/

#PageShiga.pageIndex {
    #MainImgInner {
        padding-left: 610px;
        padding-top: 160px;
        h2 {
            padding-top: 67px;
            background: url("../img/header_icon01.png") no-repeat scroll right top;
            background-size: 141px 105px;
            color: #fff;
            span:nth-of-type(3) {
                border-top: 1px solid #bababb;
            }
        }
    }
    #ContBox05 {
        border-bottom: 4px solid #235471;
        p.contactBack {
            margin-top: 17px !important;
        }
    }
    #ContBox01 {
        padding-top: 78px;
    }
    #MainImg+#Container #Main {
        margin-top: 0;
    }
    #Footer {
        padding-top: 33px;
        #FooterInfo {
            line-height: 20px;
            dd {
                padding-top: 8px;
            }
        }
        #Footertel {
            padding-top: 14px;
            li.time {
                margin-top: 6px;
            }
        }
        #FooterMail {
            margin-top: 7px;
        }
        .footerContact {
            border-bottom: 1px solid #ddd;
            padding-bottom: 30px;
        }
    }
}













































/*
 サポート共通
  -------------------------------------*/

#PageSupport {
    #Main {
        margin-top: 60px;
        h3 {
            background: transparent url("../img/contents/support_bg01.jpg") repeat scroll center top / 1280px 200px;
            width: 100%;
            height: 200px;
            margin-bottom: 24px;
            font-weight: bold;
            span.innerBasic {
                height: 250px;
                display: block;
                line-height: 200px;
                font-size: 28px;
                color: #ffffff;
                span {
                    font-weight: normal;
                }
            }
        }
        .h3note {
            letter-spacing: -0.6px;
            margin-bottom: 73px;
            padding-right: 424px;
            line-height: 32px;
        }
        h4+div.div_support {
            border: 1px solid #95bacf;
            overflow: hidden;
            div {
                display: table-cell;
                width: 499px;
                padding: 40px 30px 32px;
            }
            div:nth-of-type(2) {
                border-left: 1px solid #95bacf;
            }
            &+p {
                margin-top: 14px;
                font-size: 14px;
                color: #888888;
                letter-spacing: -0.6px;
            }
            ul.ul_support {
                margin-left: 9px;
                li {
                    background: url("../img/contents/support_icon02.png") no-repeat scroll left 5px;
                    background-size: 23px 21px;
                    font-size: 20px;
                    color: #111111;
                    padding-left: 30px;
                    line-height: 34px;
                    letter-spacing: -1px;
                    font-weight: bold;
                    &+li {
                        margin-top: 5px;
                    }
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}































/*
  トップページ・サポート共通
  -------------------------------------*/

#Page.pageIndex,
#PageShiga.pageIndex,
#PageSupport.pageIndex,
#PageSupport.pageStresscheck,
#PageSupport.pageMedicaladvisor {
    #Main {
        .support_number {
            overflow: hidden;
            counter-reset: support_number 0;
            div {
                position: relative;
                float: left;
                width: 485px;
                >span.largeBox {
                    display: block;
                    height: 160px;
                    width: 485px;
                    text-indent: -9999px;
                    transform: perspective(1px) translateZ(0px);
                    transition-duration: 0.3s;
                    transition-property: color, background-color, opacity;
                    &:hover {
                        opacity: .8;
                        span {
                            background: url("../img/contents/support_icon01.png") no-repeat center bottom 8px;
                            background-color: #339c79;
                            background-size: 9px 26px;
                        }
                    }
                    span {
                        position: absolute;
                        right: 10px;
                        top: 40px;
                        display: block;
                        background-color: #339c79;
                        width: 40px;
                        line-height: 40px;
                        height: 140px;
                        padding-top: 15px;
                        font-size: 14px;
                        font-family: 'Open Sans', sans-serif;
                        font-style: italic;
                        font-weight: 400;
                        -webkit-writing-mode: vertical-rl;
                        -ms-writing-mode: tb-rl;
                        writing-mode: vertical-rl;
                        background: url("../img/contents/support_icon01.png") no-repeat center bottom 14px;
                        background-size: 9px 26px;
                        text-indent: 0;
                        opacity: .95;
                        transform: perspective(1px) translateZ(0px);
                        transition-duration: 0.3s;
                        transition-property: color, background-color, background, opacity;
                        a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
                dl {
                    padding-left: 4px;
                    dt {
                        height: 56px;
                        color: #064c76;
                        font-size: 24px;
                        position: relative;
                        font-weight: bold;
                        padding-left: 30px;
                        padding-top: 30px;
                        /*67から変更*/
                        letter-spacing: -0.8px;
                        &:before {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            top: -35px;
                            /*0から変更*/
                            left: 0;
                            width: 31px;
                            height: 88px;
                            background: url("../img/contents/support_number_bg.png") no-repeat left top;
                            background-size: 31px 88px;
                            counter-increment: support_number 1;
                            content: counter( support_number) " ";
                            color: #fff;
                            font-size: 18px;
                            font-family: 'Open Sans', sans-serif;
                            font-style: italic;
                            font-weight: 600;
                            padding-left: 9px;
                            padding-top: 6px;
                            z-index: 97;
                            /*変更*/
                        }
                    }
                    dd {
                        color: #333;
                        font-size: 18px;
                        letter-spacing: -0.8px;
                        line-height: 30px;
                        margin-top: 14px;
                    }
                }
                &:nth-of-type(1) {
                    >span {
                        background: transparent url("../img/contents/doctor_img01.jpg") no-repeat scroll left top / 485px 160px;
                    }
                }
                &:nth-of-type(2) {
                    margin-left: 30px;
                    >span {
                        background: transparent url("../img/contents/doctor_img02.jpg") no-repeat scroll left top / 485px 160px;
                    }
                }
            }
        }
    }
}































/*
  トップページ・サポートメニュー共通
  -------------------------------------*/

#Page.pageIndex,
#PageShiga.pageIndex,
#PageSupport.pageIndex {
    #Container #Main {
        #ContBox01.support_number,
        #ContBox06.support_number {
            // div:nth-of-type(1) dl dt {
            //     background: url("../img/contents/index_number01.png") no-repeat scroll left center / 54px 38px;
            // }
            // div:nth-of-type(2) dl dt {
            //     background: url("../img/contents/index_number02.png") no-repeat scroll left center / 54px 38px;
            // }
            // div:nth-of-type(3) dl dt {
            //     background: url("../img/contents/index_number03.png") no-repeat scroll left center / 54px 38px;
            // }
            // div:nth-of-type(4) dl dt {
            //     background: url("../img/contents/index_number04.png") no-repeat scroll left center / 54px 38px;
            // }
        }
        #ContBox01.support_number,
        #ContBox06.support_number,
        #ContBox02 {
            dl {
                dt {
                    height: auto;
                    letter-spacing: 1px;
                    padding-left: 60px;
                    padding-top: 14px;
                    font-size: 26px;
                    font-weight: bold;
                    &:before {
                        background: none;
                        counter-increment: support_numbers 1;
                        content: counter( support_numbers, decimal-leading-zero) " ";
                        font-size: 50px;
                        color: #065d91;
                        position: absolute;
                        top: -7px;
                        left: -8px;
                        display: inline-block;
                        font-family: "Open Sans", sans-serif;
                        font-style: italic;
                        font-weight: 600;
                        height: 88px;
                        width: 31px;
                        padding: 0;
                        z-index: 1;
                    }
                }
                dd {
                    letter-spacing: -1px;
                    span {
                        font-size: 14px;
                        color: #888;
                    }
                }
            }
        }
        #ContBox01.support_number,
        #ContBox06.support_number {
            counter-reset: support_numbers 0;
            div {
                >span {
                    height: 180px;
                    position: relative;
                    z-index: 2;
                    &:hover {
                        span {
                            background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 10px center / 26px 9px;
                        }
                    }
                    span {
                        position: absolute;
                        top: 140px;
                        background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 14px center / 26px 9px;
                        width: 140px;
                        height: 40px;
                        writing-mode: horizontal-tb;
                        padding: 0 0 0 15px;
                        a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
                &:nth-of-type(1)>span {
                    background: transparent url("../img/contents/index_img01.jpg") no-repeat scroll left top / 485px 160px;
                }
                &:nth-of-type(2)>span {
                    background: transparent url("../img/contents/index_img02.jpg") no-repeat scroll left top / 485px 160px;
                }
                &:nth-of-type(3)>span {
                    background: transparent url("../img/contents/index_img03.jpg") no-repeat scroll left top / 485px 160px;
                }
                &:nth-of-type(4)>span {
                    background: transparent url("../img/contents/index_img04.jpg") no-repeat scroll left top / 485px 160px;
                }
                &:nth-of-type(2),
                &:nth-of-type(4) {
                    margin-left: 30px;
                }
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    margin-top: 45px;
                }
                dl {
                    dd {
                        margin-top: 9px;
                    }
                }
            }
        }
        #ContBox02.support_number {
            // div:nth-of-type(1) dl dt {
            //     background: url("../img/contents/index_number05.png") no-repeat scroll left top / 55px 38px;
            // }
            // div:nth-of-type(2) dl dt {
            //     background: url("../img/contents/index_number06.png") no-repeat scroll left top / 55px 38px;
            // }
            // div:nth-of-type(3) dl dt {
            //     background: url("../img/contents/index_number07.png") no-repeat scroll left top / 55px 38px;
            // }
        }
        #ContBox02 {
            margin-top: 73px;
            background-color: #f3f3f3;
            padding-top: 81px;
            padding-bottom: 90px;
            border-top: 4px solid #235471;
            >div.innerBasic {
                float: none;
                width: 1000px;
                div {
                    &:nth-of-type(1)>span {
                        background: transparent url("../img/contents/index_img05.jpg") no-repeat scroll left top / 315px 160px;
                    }
                    &:nth-of-type(2)>span {
                        background: transparent url("../img/contents/index_img06.jpg") no-repeat scroll left top / 315px 160px;
                    }
                    &:nth-of-type(3)>span {
                        background: transparent url("../img/contents/index_img07.jpg") no-repeat scroll left top / 315px 160px;
                    }
                    &:first-of-type {
                        dt {
                            &:before {
                                top: 0;
                            }
                        }
                    }
                    dl {

                        dt {
                            font-size: 26px;
                            line-height: 35px;
                            padding-top: 7px;
                            &:before {
                                top: 8px;
                                padding-top: 0;
                            }
                        }
                        dd {
                            margin-top: 10px;
                        }
                    }
                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        dl dt:before {
                            padding-top: 0;
                        }
                    }
                }
            }
            &+h3 span.innerBasic {
                background: transparent url("../img/contents/index_img05.jpg") no-repeat scroll right bottom / 395px 200px;
            }
        }
    }
}































/*
  サポートメニュー ストレスチェック共通 3列
  -------------------------------------*/

#Page.pageIndex #Main #ContBox02.support_number div,
#PageShiga.pageIndex #Main #ContBox02.support_number div,
#PageSupport.pageIndex #Main #ContBox02.support_number div,
#PageSupport.pageStresscheck #Main .support_number {
    div {
        width: 315px;
        >span {
            width: 315px !important;
            height: 180px !important;
        }
        &:nth-of-type(1) {
            >span {
                background: transparent url("../img/contents/stress_img01.png") no-repeat scroll left top / 315px 160px;
            }
        }
        &:nth-of-type(2) {
            >span {
                background: transparent url("../img/contents/stress_img02.png") no-repeat scroll left top / 315px 160px;
            }
        }
        &:nth-of-type(3) {
            >span {
                background: transparent url("../img/contents/stress_img03.png") no-repeat scroll left top / 315px 160px;
            }
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
            margin-left: 27px;
            dl {
                dt {
                    line-height: 29px;
                    padding-top: 0;
                    &:before {
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

























/*
  サポート共通
  -------------------------------------*/

#PageSupport {
    div#MainImgInner {
        h2 {
            font-weight: bold;
            span:nth-of-type(2) {
                padding-left: 0;
                .h2number {
                    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
                    color: #065d91;
                    display: inline-block;
                    font-family: "Open Sans", sans-serif;
                    font-size: 40px;
                    font-style: italic;
                    font-weight: 600;
                    padding: 0;
                    padding-right: 15px;
                }
            }
        }
    }
}














/*
  サポートメニュー
  -------------------------------------*/

#PageSupport.pageIndex {
    div#MainImgInner {
        h2 {
            font-size: 28px;
            color: #111;
            line-height: 240px;
            padding-top: 0;
            background: url("../img/contents/index_icon01.png") no-repeat scroll left center;
            background-size: 42px 36px;
            padding-left: 60px;
        }
    }
    #Container #Main {
        counter-reset: h3_numbers 4;
        h3 {
            span.innerBasic {
                padding-top: 65px;
                line-height: 34px;
                &:before {
                    color: #daf1ff;
                    content: counter(h3_numbers, decimal-leading-zero) " ";
                    counter-increment: h3_numbers 1;
                    font-family: "Open Sans", sans-serif;
                    font-size: 40px;
                    font-style: italic;
                    font-weight: 600;
                }
                span {
                    display: block;
                    font-size: 18px;
                }
            }
        }
        #ContBox03,
        #ContBox04,
        #ContBox05 {
            .div_support div {
                width: 100%;
            }
            &+h3 span.innerBasic {
                padding-top: 80px;
            }
            .ul_support {
                li {
                    font-weight: bold;
                }
            }
        }
        #ContBox03 {
            ul li {
                font-weight: bold;
            }
            .div_support {
                margin-bottom: 78px;
                div {

                    padding: 33px 30px 32px;
                }
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    margin-bottom: 0;
                    &+p {
                        color: #333333;
                        margin-bottom: 72px;
                        line-height: 23px;
                    }
                }
                &:nth-of-type(3) {
                    background: transparent url("../img/contents/support_img01.jpg") no-repeat scroll left 34px top 28px / 46px 286px;
                    div {
                        padding: 43px 30px 38px;
                        ul li {
                            color: #185479;
                            font-size: 18px;
                            padding-left: 58px;
                            &+li {
                                margin-top: 42px;
                            }
                        }
                    }
                }
            }
            &+h3 span.innerBasic {
                background: transparent url("../img/contents/index_img06.jpg") no-repeat scroll right bottom / 395px 200px;
            }
        }
        #ContBox04 {
            .div_support {
                margin-bottom: 100px;
                div {
                    padding: 32px 30px 27px;
                }
            }
            &+h3 span.innerBasic {
                background: transparent url("../img/contents/index_img07.jpg") no-repeat scroll right bottom / 395px 200px;
            }
        }
        #ContBox05 {
            border-bottom: 4px solid #235471;
            .div_support {
                margin-bottom: 100px;
                div {
                    padding: 33px 30px 28px;
                }
            }
        }
        #ContBox06 {
            padding-top: 80px;
            padding-bottom: 100px;
        }
    }
}































/*
  メンタルヘルス研修
  -------------------------------------*/

#PageSupport.pageMentalhealth {
    h3 {
        span.innerBasic {
            background: transparent url("../img/contents/training_img01.jpg") no-repeat scroll right bottom / 395px 200px;
        }
    }
    #Main {
        #ContSubBox01 {
            h4 {
                letter-spacing: 2px;
            }
            ul.ul_work {
                overflow: hidden;
                li {
                    float: left;
                    width: 200px;
                    height: 200px;
                    border: 1px solid #95bacf;
                    border-right: none;
                    font-size: 20px;
                    color: #065d91;
                    text-align: center;
                    line-height: 31px;
                    padding-top: 113px;
                    letter-spacing: -1px;
                    font-weight: bold;
                    &:nth-of-type(1) {
                        background: url("../img/contents/training_icon01.png") no-repeat scroll center top 28px;
                        background-size: 65px 65px;
                    }
                    &:nth-of-type(2) {
                        background: url("../img/contents/training_icon02.png") no-repeat scroll center top 38px;
                        background-size: 66px 49px;
                    }
                    &:nth-of-type(3) {
                        background: url("../img/contents/training_icon03.png") no-repeat scroll center top 39px;
                        background-size: 47px 47px;
                    }
                    &:nth-of-type(4) {
                        background: url("../img/contents/training_icon04.png") no-repeat scroll center top 31px;
                        background-size: 48px 60px;
                    }
                    &:nth-of-type(5) {
                        background: url("../img/contents/training_icon05.png") no-repeat scroll center top 36px;
                        background-size: 64px 54px;
                        border-right: 1px solid #95bacf;
                    }
                }
            }
        }
        #ContSubBox02 {
            margin-top: 79px;
            p.self_care,
            p.line_care {
                margin-bottom: 21px;
                display: block;
                width: 121px;
                height: 31px;
                line-height: 31px;
                background-color: #185479;
                border-radius: 15px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                font-weight: bold;
            }
            p.line_care {
                background-color: #22906c;
            }
        }
        #ContSubBox03 {
            margin-top: 70px;
            margin-bottom: 113px;
            h4+div {
                border: 1px solid #95bacf;
                height: 349px;
                text-align: center;
                padding-top: 30px;
            }
        }
    }
}































/*
  ヒューマンエラー
  -------------------------------------*/

#PageSupport.pageHumanerror {
    #Main {
        h3 {
            span.innerBasic {
                background: transparent url("../img/contents/human_img01.png") no-repeat scroll right bottom / 395px 200px;
            }
        }
        .h3note {
            margin-bottom: 21px;
        }
        .h3note+div {
            border: 1px solid #95bacf;
            padding: 34px 38px;
            p {
                font-size: 20px;
                color: #065d91;
                letter-spacing: -1.3px;
                font-weight: bold;
            }
        }
        h4 {
            margin-top: 80px;
            span {
                font-size: 14px;
                letter-spacing: 0;
            }
        }
        h4+div.div_support div {
            padding: 31px 30px 26px;
        }
        #ContSubBox02 {
            margin-bottom: 56px;
            h4+div {
                position: relative;
                top: -88px;
            }
        }
    }
}































/*
  ストレスチェック  産業医・カウンセラー契約 見出し共通
  -------------------------------------*/

#PageSupport.pageStresscheck,
#PageSupport.pageMedicaladvisor {
    #Main {
        counter-reset: h3_number 0;
        >h3 {
            >span {
                position: relative;
                &:before {
                    border: 1px solid #b2cedf;
                    border-radius: 50%;
                    color: #b2cedf;
                    content: "";
                    font-size: 28px;
                    height: 50px;
                    margin-right: 20px;
                    padding: 5px 10px 5px 13px;
                    width: 50px;
                    font-family: 'Open Sans', sans-serif;
                    font-style: italic;
                    font-weight: 600;
                    counter-increment: h3_number 1;
                    content: counter(h3_number) " ";
                }
            }
        }
    }
}































/*
  ストレスチェック
  -------------------------------------*/

#PageSupport.pageStresscheck {
    #Main {
        #ContBox01+h3 {
            margin-top: 75px;
            span.innerBasic {
                background: transparent url("../img/contents/stress_img01.png") no-repeat scroll right bottom / 395px 200px;
            }
        }
        #ContBox02+h3 {
            margin-top: 75px;
            span.innerBasic {
                background: transparent url("../img/contents/stress_img02.png") no-repeat scroll right bottom / 395px 200px;
            }
        }
        #ContBox03+h3 {
            margin-top: 75px;
            span.innerBasic {
                background: transparent url("../img/contents/stress_img03.png") no-repeat scroll right bottom / 395px 200px;
            }
        }
        #ContBox01 {
            div {
                width: 315px;
                >span.largeBox {
                    position: relative;
                    z-index: 10;
                    width: 315px !important;
                    height: 180px !important;
                    span {
                        z-index: 100;
                    }
                }
                dl {
                    // position: relative !important;
                    // top: -35px !important;
                    z-index: 15;
                }
            }
        }
        #ContBox02 {
            overflow: hidden;
            &+h3 {
                margin-top: 102px;
            }
            .div_support {
                div:nth-of-type(1) {
                    width: 794px;
                    padding: 28px 30px 27px;
                }
                div:nth-of-type(2) {
                    width: 204px;
                    p {
                        font-size: 18px;
                        color: #065d91;
                        letter-spacing: -1px;
                        font-weight: bold;
                        span {
                            margin-top: 12px;
                            display: block;
                            font-size: 14px;
                            color: #888888;
                            line-height: 23px;
                        }
                    }
                }
                &+h4 {
                    margin-top: 71px;
                    &+.div_support {
                        div {
                            display: block;
                            width: 100%;
                            &:nth-of-type(1) {
                                text-align: center;
                            }
                            &:nth-of-type(2) {
                                border-left: none;
                                border-top: 1px solid #95bacf;
                                padding: 30px 30px 32px 81px;
                                background: transparent url("../img/contents/stress_img05.png") no-repeat scroll left 41px top 29px / 32px 461px;
                                ul {
                                    li {
                                        color: #333;
                                        font-size: 18px;
                                        line-height: 31px; // letter-spacing: -1px;
                                        &+li {
                                            margin-top: 9px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ul_support {
                span {
                    font-size: 14px;
                    color: #888888;
                }
            }
        }
        #ContBox03 {
            .div_support {
                div {
                    display: block;
                    width: 100%;
                    &:nth-of-type(1) {
                        padding: 33px 30px 12px;
                        .ul_support+ul {
                            margin-top: 28px;
                            li {
                                display: block;
                                height: 149px;
                                font-size: 18px;
                                color: #333;
                                padding-top: 60px;
                                padding-left: 109px;
                                line-height: 34px;
                                letter-spacing: -1px;
                                &:nth-of-type(1) {
                                    background: transparent url("../img/contents/stress_img06.jpg") no-repeat scroll left top / 490px 149px;
                                    span {
                                        display: block;
                                        font-size: 14px;
                                        color: #888;
                                    }
                                }
                                &:nth-of-type(2) {
                                    margin-top: 7px;
                                    background: transparent url("../img/contents/stress_img07.jpg") no-repeat scroll left top / 490px 149px;
                                }
                                &:nth-of-type(3) {
                                    margin-top: 11px;
                                    background: transparent url("../img/contents/stress_img08.jpg") no-repeat scroll left top / 490px 149px;
                                }
                            }
                        }
                    }
                    &:nth-of-type(2) {
                        padding: 34px 30px;
                        border-left: none;
                        border-top: 1px solid #95bacf;
                    }
                }
            }
        }
        #ContBox04 {
            margin-bottom: 119px;
            .div_support {
                div {
                    display: block;
                    width: 100%;
                    &:nth-of-type(1) {
                        padding: 34px 30px;
                    }
                    &:nth-of-type(2) {
                        padding: 39px 30px 26px;
                        border-left: none;
                        border-top: 1px solid #95bacf;
                    }
                }
            }
            p.h4_title {
                margin-bottom: 4px;
                letter-spacing: -2px;
                &+p span {
                    font-size: 14px;
                    color: #888;
                }
            }
        }
        h3#Stress_seat {
            margin-top: 100px;
        }
        p.h4_title {
            font-size: 20px;
            color: #065d91;
            letter-spacing: -1px;
            margin-bottom: 13px;
            font-weight: bold;
            &+p {
                line-height: 33px;
                letter-spacing: -1px;
            }
        }
    }
}































/*
  産業医・カウンセラー契約
  -------------------------------------*/

#PageSupport.pageMedicaladvisor #Main #ContBox01.support_number {
    .largeBox {
        height: 180px;
    }
    div dl {
        top: 0;
        dt:before {
            top: -36px;
        }
        dd {
            margin-top: 11px;
        }
    }
}

#PageSupport.pageMedicaladvisor {
    #Main {
        #ContBox01 {
            div {
                dl {
                    padding-left: 4px;
                    position: relative;
                    top: -15px;
                }
            }
            &+h3 {
                margin-top: 93px;
                span.innerBasic {
                    background: transparent url("../img/contents/doctor_img03.png") no-repeat scroll right bottom / 395px 200px;
                }
            }
        }
        #ContBox02,
        #ContBox03 {
            h4+div.div_support {
                border: 1px solid #95bacf;
                overflow: hidden;
                div {
                    display: block;
                    width: 100%;
                    padding: 33px 30px;
                    &:nth-of-type(2) {
                        border-top: 1px solid #95bacf;
                        border-left: none;
                        p {
                            line-height: 33px;
                        }
                    }
                }
            }
        }
        #ContBox02+h3 {
            margin-top: 100px;
            span.innerBasic {
                background: transparent url("../img/contents/doctor_img04.png") no-repeat scroll right bottom / 395px 200px;
            }
        }
        #ContBox03 {
            margin-bottom: 146px;
        }
    }
}































/*
 サポート実績共通 見出し
  -------------------------------------*/

#PageCase.pageCategory #Main,
#PageCase.pageMonthly #Main {
    #ContBox01 {
        h3 {
            color: #111;
            font-size: 22px;
            font-weight: bold;
            line-height: 50px;
            margin-bottom: 66px;
        }
    }
}











































/*
  カテゴリアーカイブ（サポート実績）
  -------------------------------------*/

#PageCase.pageCategory #Main {
    #ContBox01 {
        h3 {
            color: #111;
            font-size: 22px;
            font-weight: bold;
            line-height: 50px;
            margin-bottom: 66px;
        }
    }
}











































/*
  月別アーカイブ（サポート実績）
  -------------------------------------*/

#PageCase.pageMonthly #Main {
    #ContBox01 {
        div.entryBlock:last-of-type {
            border-bottom: none;
        }
    }
}













































/*
  記事詳細（サポート実績）
  -------------------------------------*/

#PageCase.pageEntry #Main {
    #ContBox01 {
        #ContSubBox01 {
            margin-bottom: 80px;
            div.entryBlock {
                padding-top: 0;
                border-top: none;
                border-bottom: 5px solid #235471;
                &:hover {
                    opacity: 1;
                }
            }
            .div_demand,
            .div_step,
            .div_voice {
                margin-top: 30px;
                padding: 40px 35px 32px;
                .div_demand_title,
                .div_step_title,
                .div_voice_title {
                    display: block;
                    height: 31px;
                    line-height: 31px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    margin-bottom: 28px;
                    border-radius: 15px;
                }
                p+p+p {
                    margin-top: 31px;
                }
                .div_demand_title {
                    width: 97px;
                    background-color: #185479;
                }
                .div_step_title {
                    width: 83px;
                    background-color: #22906c;
                }
                .div_voice_title {
                    width: 95px;
                    background-color: #b37c36;
                }
            }
            .div_demand {
                border: 1px solid #95bacf;
            }
            .div_step {
                border: 1px solid #95c7b7;
            }
            .div_voice {
                border: 1px solid #d1ac7d;
            }
        }
    }
}













































/*
  組織案内
  -------------------------------------*/

#PageClinic.pageIndex {
    #Main {
        #ContBox01 {
            #ContSubBox01 {
                margin-top: 57px;
                img+p {
                    text-align: center;
                    font-size: 16px;
                    color: #333;
                    padding-top: 9px;
                    letter-spacing: -1px;
                }
                img+p+div {
                    width: 688px;
                    margin: 26px auto 36px;
                    padding-top: 0;
                    padding-bottom: 8px;
                    background: url("../img/contents/soshiki_img02.png") no-repeat scroll left top,
                    url("../img/contents/soshiki_img03.png") no-repeat scroll right bottom;
                    p {
                        text-align: center;
                        font-size: 22px;
                        line-height: 40px;
                        color: #111111;
                        font-weight: bold;
                    }
                }
                div.greeting {
                    border: 1px solid #95bacf;
                    padding: 34px 39px 30px;
                    p {
                        &.greeting_title {
                            font-size: 20px;
                            font-weight: bold;
                            color: #065d91;
                            span {
                                font-size: 14px;
                                margin-left: 18px;
                            }
                            &+p {
                                margin-top: 16px;
                            }
                        }
                        font-size:18px;
                        color:#333333;
                        letter-spacing: -1px;
                        line-height: 33px;
                        &+p {
                            margin-top: 21px;
                        }
                    }
                }
            }
            #ContSubBox02 {
                margin-top: 78px;
                margin-bottom: 124px;
                div {
                    dl {
                        border-top: 1px solid #dadada;
                        display: table;
                        padding: 14px 0 15px;
                        width: 100%;
                        color: #000;
                        font-size: 18px;
                        line-height: 30px;
                        &:nth-of-type(3) {
                            dd {
                                img {
                                    position: relative;
                                    top: -7px;
                                }
                            }
                            a {
                                color: #555;
                                text-decoration: none;
                            }
                        }
                        &:nth-of-type(4) {
                            dd {
                                img {
                                    position: relative;
                                    top: -6px;
                                }
                            }
                        }
                        dt {
                            display: table-cell;
                            width: 199px;
                            font-weight: bold;
                        }
                        dd {
                            color: #555;
                            display: table-cell;
                        }
                        &.logo dd a {
                            display: block;
                            background: url("../img/contents/soshiki_img04.png") no-repeat scroll left top;
                            background-size: 200px 55px;
                            width: 200px;
                            height: 55px;
                            overflow: hidden;
                            text-indent: 100%;
                            white-space: nowrap;
                        }
                        &:last-child {
                            border-bottom: 1px solid #dadada;
                        }
                    }
                }
            }
        }
    }
}





















/*
  お問い合わせ
  -------------------------------------*/

#PageContact.pageIndex #Main {
    #ContBox01 {
        #ContSubBox02 {
            background-color: #f3f3f3;
            border-top: 4px solid #235471;
            margin-top: 20px;
            padding-bottom: 79px;
            padding-top: 60px;
            h4 {
                color: #111;
                margin-top: 18px;
                &:after {
                    margin-bottom: 32px;
                }
                &+p {
                    line-height: 35px;
                    letter-spacing: -1.5px;
                    margin-bottom: 32px;
                }
            }
            div.innerBasic {
                div {
                    padding: 37px 40px 34px;
                    background-color: #fff;
                    margin-bottom: 10px;
                    p,
                    ul li {
                        font-size: 16px;
                        color: #333;
                        line-height: 30px;
                        letter-spacing: -1px;
                    }
                    ul {
                        margin-top: 14px;
                    }
                    p+p {
                        margin-top: 15px;
                    }
                    p.policy_title {
                        font-size: 18px;
                        color: #111;
                        margin-bottom: 17px;
                        font-weight: bold;
                    }
                    p.tel {
                        img{
                            vertical-align:middle;
                        }
                        a {
                            color: #333 !important;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}









































/*
  お問い合わせform共通
  -------------------------------------*/

#PageContact.pageIndex,
#PageContact.pageConfirm {
    #Main {
        h3.h4 {
            margin-top: 58px;
        }
        #form1 {
            margin-top: 34px;
            dl:first-of-type {
                width: 100%;
                border-top: 1px solid #e6e6e6;
                border-bottom: 1px solid #dadada;
                padding-bottom: 29px;
                dt {
                    span {
                        margin-top: 20px;
                        display: block;
                        color: #666;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
                dd:nth-of-type(2) {
                    padding-left: 10px;
                }
            }
            dl+dl {
                width: 100%;
                border-bottom: 1px solid #dadada;
            }
            dl {
                dt {
                    display: table-cell;
                    width: 215px;
                    padding-top: 29px;
                    padding-bottom: 30px;
                    color: #333;
                    font-size: 18px;
                    vertical-align: top;
                    font-weight: bold;
                    line-height: 20px;
                }
                dd {
                    display: table-cell;
                    color: #333;
                    font-size: 18px;
                    line-height: 20px;
                    div.checker {
                        margin-top: 10px;
                        margin-left: 11px;
                        span {
                            display: inline-block;
                            width: 23px;
                            height: 23px;
                            line-height: 24px;
                            background: rgba(0, 0, 0, 0) url("../img/contents/mail_img01.jpg") no-repeat scroll left center;
                            &.checked {
                                background: rgba(0, 0, 0, 0) url("../img/contents/mail_img02.jpg") no-repeat scroll left center;
                            }
                        }
                        input {
                            height: 25px;
                            width: 25px;
                        }
                        &+label {
                            vertical-align: bottom;
                        }
                    }
                }
                dd:nth-of-type(1) {
                    width: 63px;
                    padding-top: 26px;
                    padding-left: 0;
                    text-align: center;
                    &>span {
                        display: inline-block;
                        width: 51px;
                        height: 27px;
                        line-height: 27px;
                        background-color: #446e88;
                        border-radius: 13px;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                    }
                    .required {
                        background: #dc896c none repeat scroll 0 0;
                    }
                }
                dd:nth-of-type(3) {
                    vertical-align: middle;
                }
                input[type="text"] {
                    width: 420px;
                    height: 45px;
                    margin-left: 20px;
                    padding-left: 17px;
                    border: 1px solid #95bacf;
                    background-color: #f4fafd;
                    color: #333;
                    font-size: 18px;
                    border-radius: 0 !important;
                    &:focus {
                        border: 1px solid #2073a4;
                        background-color: #fff;
                    }
                }
                div.selector {
                    overflow: visible;
                    width: 260px !important;
                    height: 45px !important;
                    margin-left: 20px;
                    span {
                        display: inline-block;
                        height: 45px;
                        line-height: 45px;
                        vertical-align: middle;
                        width: 260px !important;
                        background-image: url(../img/contents/form_select3a.png), url(../img/contents/form_select3.png);
                        background-repeat: no-repeat, no-repeat;
                        background-position: 94% center, left center;
                        background-size: 12px 8px, 100% 45px;
                        padding-left: 18px;
                    }
                    >.selector {
                        background: none;
                        margin-left: 0;
                    }
                    select {
                        height: 45px;
                        cursor: pointer;
                        display: inline-block;
                        width: 260px !important;
                        vertical-align: middle;
                        line-height: 46px;
                        color: #999;
                        option {
                            color: #333;
                            padding-left: 18px;
                        }
                    }
                    .example2 {
                        color: blue !important;
                    }
                }
                textarea {
                    width: 700px;
                    height: 195px;
                    margin-top: 18px;
                    margin-bottom: 16px;
                    margin-left: 20px;
                    padding-top: 10px;
                    padding-left: 17px;
                    border: 1px solid #95bacf;
                    background-color: #f4fafd;
                    color: #333;
                    resize: none;
                    border-radius: 0 !important;
                }
            }
            .postMark {
                position: relative;
                left: 20px;
            }
            dl {
                #text3 {
                    margin-left: 22px;
                    width: 150px;
                }
                #text4 {
                    width: 514px;
                    margin-left: 7px !important;
                }
                #text5,
                #text6,
                #text7 {
                    width: 320px;
                }
                #text8,
                #text9 {
                    width: 220px;
                }
                label {
                    span {
                        font-size: 14px;
                        color: #666666;
                        vertical-align: bottom;
                    }
                }
            }
            textarea:focus {
                border: 1px solid #2073a4;
                background-color: #fff;
            }
            li.confirm {
                display: block;
                width: 1000px;
                margin: 0 auto;
                text-align: center;
            }
            ul.contact-form1 li {
                display: table-cell;
                height: 50px;
                vertical-align: middle;
            }
            li.reset input[type="button"] {
                color: #666;
                font-size: 13px;
                text-decoration: underline;
            }
            #image-btn {
                background: #4aa07a url("../img/contents/contact_img01.png") no-repeat scroll right 19px center;
                display: block;
                width: 347px;
                height: 78px;
                margin: 60px auto 80px;
                color: #fff;
                font-size: 18px;
                line-height: 78px;
                font-weight: bold;
                transform: perspective(1px) translateZ(0px);
                transition-duration: 0.3s;
                transition-property: color, background-color, background, opacity;
                &:hover {
                    opacity: .8;
                    background: #4aa07a url("../img/contents/contact_img01.png") no-repeat scroll right 15px center;
                }
            }
        }
    }
}


#PageContact.pageIndex #Main #form1 dl+dl,
#PageContact.pageConfirm #Container #form1 #PageContact.pageIndex #Main #form1 dl,
#PageContact.pageConfirm #Container #form1 dl {
    display: table;
}

















/* placeholder */

#PageContact.pageIndex #Main #form1 dl input[type="text"]::-webkit-input-placeholder {
    font-size: 18px;
    color: #999;
}

#PageContact.pageIndex #Main #form1 dl textarea::-webkit-input-placeholder {
    font-size: 18px;
    color: #999;
    line-height: 33px;
}

#PageContact.pageIndex #Main #form1 dl input[type="text"]:-ms-input-placeholder {
    font-size: 18px;
    color: #999;
}

#PageContact.pageIndex #Main #form1 dl textarea:-ms-input-placeholder {
    font-size: 18px;
    color: #999;
    line-height: 33px;
}

#PageContact.pageIndex #Main #form1 dl input[type="text"]:-moz-placeholder {
    font-size: 18px;
    color: #999;
}

#PageContact.pageIndex #Main #form1 dl input[type="text"]::-moz-placeholder {
    font-size: 18px;
    color: #999;
    opacity: 1;
}

#PageContact.pageIndex #Main #form1 dl textarea:-moz-placeholder {
    font-size: 18px;
    color: #999;
    line-height: 33px;
}

























/* checkbox */

div#uniform-text1_1 {
    margin-top: 0 !important;
    &+label {
        margin-top: 0 !important;
    }
}


dl.contact dd:nth-of-type(1) {
    vertical-align: top;
    position: relative;
    top: -3px;
}







































/*
  お問い合わせ 確認
  -------------------------------------*/

#PageContact.pageConfirm #Main {
    #ContBox01 {
        dl:first-of-type {
            padding-bottom: 0;
        }
        dd {
            padding-left: 18px;
            padding-bottom: 0;
        }
        dl:last-of-type {
            padding-bottom: 0;
            dd {
                padding-top: 28px;
                padding-bottom: 28px;
                line-height: 29px;
            }
        }
        .postMark {
            position: relative;
            left: 0 !important;
        }
        li.reset input[type="button"] {
            display: block;
            height: 35px;
            margin: 60px auto 80px;
            color: #1c7c5c;
            font-size: 14px;
            line-height: 35px;
            &:hover {
                text-decoration: none;
            }
        }
    }
}













































/*
  お問い合わせ 完了
  -------------------------------------*/

#PageContact.pageThanks #Main {
    #ContBox01 {
        margin-bottom: 101px;
        h3.h4 {
            margin-top: 60px;
            &:after {
                margin-bottom: 34px;
            }
        }
        p {
            line-height: 33px;
        }
        p+p {
            margin-top: 19px;
        }
    }
}













































/*
  アイコン共通
  -------------------------------------*/

#MainImg+#Container #Main {
    p.contactBack {
        border: 1px solid #289772;
        margin-left: auto;
        width: 149px;
        text-align: center;
        a {
            display: block;
            height: 48px;
            line-height: 50px;
            text-decoration: none;
            font-size: 16px;
            &:hover {
                background-color: #339c79;
                color: #fff;
            }
        }
    }

    .s_more {
        background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 14px center / 26px 9px;
        height: 40px;
        padding: 0 0 0 15px;
        top: 140px;
        width: 140px;
        margin-left: auto;
        display: block;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 40px;
        text-indent: 0;
        transform: perspective(1px) translateZ(0px);
        transition-duration: 0.3s;
        transition-property: color, background-color, background, opacity;
        a {
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            text-decoration: none;
        }
        &:hover {
            opacity: .8;
            background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 10px center / 26px 9px;
        }
    }
}