@charset "UTF-8";
@import url(base.css);
@import url(common.css);
/*---------------------------------
Page CSS 2017.07
CSS 担当者名 Document 2017.07
Last Update 2017.07 担当者名
---------------------------------*/
/*
  404
-------------------------------------*/
/*
トップページ共通
  -------------------------------------*/
#Page.pageIndex #MainImgInner,
#PageShiga.pageIndex #MainImgInner {
  height: 540px;
  padding-top: 133px;
  padding-left: 19px; }
  #Page.pageIndex #MainImgInner h2,
  #PageShiga.pageIndex #MainImgInner h2 {
    width: 390px;
    color: #065d91;
    font-size: 28px;
    line-height: 44px;
    padding-left: 0;
    font-weight: bold; }
    #Page.pageIndex #MainImgInner h2 span:nth-of-type(1),
    #PageShiga.pageIndex #MainImgInner h2 span:nth-of-type(1) {
      position: relative;
      top: -8px;
      font-size: 16px;
      line-height: 1.0; }
    #Page.pageIndex #MainImgInner h2 span:nth-of-type(2),
    #PageShiga.pageIndex #MainImgInner h2 span:nth-of-type(2) {
      letter-spacing: -5px; }
    #Page.pageIndex #MainImgInner h2 span:nth-of-type(3),
    #PageShiga.pageIndex #MainImgInner h2 span:nth-of-type(3) {
      border-top: 1px solid #9abdd2;
      display: block;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.5px;
      line-height: 1;
      margin-top: 11px;
      padding-top: 16px;
      white-space: nowrap; }

#Page.pageIndex #MainImg + #Container #Main,
#PageShiga.pageIndex #MainImg + #Container #Main {
  margin-top: 81px; }
  #Page.pageIndex #MainImg + #Container #Main #ContBox01 h3.h4,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox01 h3.h4 {
    color: #111;
    background: transparent url("../img/contents/index_icon01.png") no-repeat scroll left top/28px 24px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox01 h3.h4 span,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox01 h3.h4 span {
      padding-left: 39px; }
  #Page.pageIndex #MainImg + #Container #Main #ContBox02,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox02 {
    margin-top: 5px;
    padding-top: 40px;
    padding-bottom: 52px;
    background-color: #fff;
    border-top: none; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox02 div > span.largeBox:hover span,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox02 div > span.largeBox:hover span {
      background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 10px center/26px 9px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox02 div > span.largeBox span,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox02 div > span.largeBox span {
      background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 14px center/26px 9px;
      height: 40px;
      padding: 0 0 0 15px;
      position: absolute;
      top: 140px;
      width: 140px;
      writing-mode: horizontal-tb; }
  #Page.pageIndex #MainImg + #Container #Main #ContBox03,
  #Page.pageIndex #MainImg + #Container #Main #ContBox04,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 {
    background-color: #f3f3f3;
    border-top: 4px solid #235471;
    margin-top: 20px;
    padding-bottom: 79px;
    padding-top: 60px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1),
    #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1),
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1),
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) {
      background: url("../img/contents/index_img08.jpg") no-repeat scroll right center;
      background-size: 171px 171px;
      height: 171px;
      overflow: hidden;
      margin-bottom: 38px; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1) h4,
      #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) h4,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1) h4,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) h4 {
        float: left;
        width: 220px;
        font-size: 22px;
        color: #111111;
        padding-top: 18px; }
        #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1) h4 span,
        #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) h4 span,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1) h4 span,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) h4 span {
          padding-left: 39px; }
        #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1) h4 + div,
        #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) h4 + div,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.innerBasic > div:nth-of-type(1) h4 + div,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) h4 + div {
          float: left;
          width: 580px;
          padding-top: 18px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox03 p.contactBack,
    #Page.pageIndex #MainImg + #Container #Main #ContBox04 p.contactBack,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 p.contactBack,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 p.contactBack {
      margin-top: 14px;
      width: 215px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl,
    #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl {
      width: 100%;
      display: block;
      margin-bottom: 4px;
      background-color: #fff;
      height: 76px;
      line-height: 76px;
      background: url("../img/contents/index_icon03.png") no-repeat scroll right 19px center;
      background-size: 12px 21px;
      transform: perspective(1px) translateZ(0px);
      transition-duration: 0.3s;
      transition-property: color, background-color, background, opacity; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl:hover,
      #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl:hover,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl:hover,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl:hover {
        background-color: #fff;
        opacity: .8;
        background: url("../img/contents/index_icon03.png") no-repeat scroll right 15px center; }
        #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl:hover a,
        #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl:hover a,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl:hover a,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl:hover a {
          text-decoration: underline;
          opacity: 1; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl:after,
      #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl:after,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl:after,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl:after {
        clear: both;
        content: "";
        display: block; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl dt,
      #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl dt,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl dt,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl dt {
        float: left;
        width: 121px;
        text-align: center;
        font-size: 14px;
        color: #d8a668;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-style: italic; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl dd,
      #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl dd,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl dd,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl dd {
        width: 810px;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: -1px; }
        #Page.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl dd a,
        #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl dd a,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 div.divSemiSapo dl dd a,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.divSemiSapo dl dd a {
          font-size: 18px;
          color: #064c76;
          font-weight: bold;
          text-decoration: none; }
  #Page.pageIndex #MainImg + #Container #Main #ContBox03 h4,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox03 h4 {
    background: url("../img/contents/index_icon02.png") no-repeat scroll left top 18px;
    background-size: 28px 26px; }
  #Page.pageIndex #MainImg + #Container #Main #ContBox04,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 {
    margin-top: 0; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1),
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 div.innerBasic > div:nth-of-type(1) {
      background: none;
      height: auto;
      margin-bottom: 0; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox04 h4,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 h4 {
      background: url("../img/contents/achievement_icon01.png") no-repeat scroll left top 16px;
      background-size: 33px 25px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox04 p.contactBack,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox04 p.contactBack {
      margin-top: 0;
      width: 400px; }
  #Page.pageIndex #MainImg + #Container #Main #ContBox05,
  #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 {
    background: url("../img/contents/index_bg.png") repeat scroll center top;
    background-size: 2560px 534px;
    border-top: 4px solid #235471;
    padding-top: 80px;
    padding-bottom: 83px; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic {
      background: url("../img/contents/index_img09.png") no-repeat scroll left top 66px;
      background-size: 485px 302px; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4 {
        font-size: 26px;
        color: #fff;
        text-align: center; }
        #Page.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4:after,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4:after {
          display: none; }
        #Page.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4 + div,
        #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4 + div {
          margin-top: 39px;
          padding-top: 40px;
          padding-bottom: 26px;
          padding-left: 515px;
          background: url("../img/contents/index_icon05.png") no-repeat scroll left 515px top 5px, transparent url("../img/contents/index_icon06.png") no-repeat scroll right bottom; }
          #Page.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4 + div p,
          #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 div.innerBasic h4 + div p {
            font-size: 18px;
            line-height: 33px;
            color: #fff; }
    #Page.pageIndex #MainImg + #Container #Main #ContBox05 p.contactBack,
    #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 p.contactBack {
      margin-top: 50px;
      background-color: #fff;
      width: 205px;
      height: 50px;
      border: 1px solid #289772; }
      #Page.pageIndex #MainImg + #Container #Main #ContBox05 p.contactBack a:hover,
      #PageShiga.pageIndex #MainImg + #Container #Main #ContBox05 p.contactBack a:hover {
        border: 1px solid #fff;
        line-height: 49px; }

/*
  トップページ（滋賀用）
  -------------------------------------*/
#PageShiga.pageIndex #MainImgInner {
  padding-left: 610px;
  padding-top: 160px; }
  #PageShiga.pageIndex #MainImgInner h2 {
    padding-top: 67px;
    background: url("../img/header_icon01.png") no-repeat scroll right top;
    background-size: 141px 105px;
    color: #fff; }
    #PageShiga.pageIndex #MainImgInner h2 span:nth-of-type(3) {
      border-top: 1px solid #bababb; }

#PageShiga.pageIndex #ContBox05 {
  border-bottom: 4px solid #235471; }
  #PageShiga.pageIndex #ContBox05 p.contactBack {
    margin-top: 17px !important; }

#PageShiga.pageIndex #ContBox01 {
  padding-top: 78px; }

#PageShiga.pageIndex #MainImg + #Container #Main {
  margin-top: 0; }

#PageShiga.pageIndex #Footer {
  padding-top: 33px; }
  #PageShiga.pageIndex #Footer #FooterInfo {
    line-height: 20px; }
    #PageShiga.pageIndex #Footer #FooterInfo dd {
      padding-top: 8px; }
  #PageShiga.pageIndex #Footer #Footertel {
    padding-top: 14px; }
    #PageShiga.pageIndex #Footer #Footertel li.time {
      margin-top: 6px; }
  #PageShiga.pageIndex #Footer #FooterMail {
    margin-top: 7px; }
  #PageShiga.pageIndex #Footer .footerContact {
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px; }

/*
 サポート共通
  -------------------------------------*/
#PageSupport #Main {
  margin-top: 60px; }
  #PageSupport #Main h3 {
    background: transparent url("../img/contents/support_bg01.jpg") repeat scroll center top/1280px 200px;
    width: 100%;
    height: 200px;
    margin-bottom: 24px;
    font-weight: bold; }
    #PageSupport #Main h3 span.innerBasic {
      height: 250px;
      display: block;
      line-height: 200px;
      font-size: 28px;
      color: #ffffff; }
      #PageSupport #Main h3 span.innerBasic span {
        font-weight: normal; }
  #PageSupport #Main .h3note {
    letter-spacing: -0.6px;
    margin-bottom: 73px;
    padding-right: 424px;
    line-height: 32px; }
  #PageSupport #Main h4 + div.div_support {
    border: 1px solid #95bacf;
    overflow: hidden; }
    #PageSupport #Main h4 + div.div_support div {
      display: table-cell;
      width: 499px;
      padding: 40px 30px 32px; }
    #PageSupport #Main h4 + div.div_support div:nth-of-type(2) {
      border-left: 1px solid #95bacf; }
    #PageSupport #Main h4 + div.div_support + p {
      margin-top: 14px;
      font-size: 14px;
      color: #888888;
      letter-spacing: -0.6px; }
    #PageSupport #Main h4 + div.div_support ul.ul_support {
      margin-left: 9px; }
      #PageSupport #Main h4 + div.div_support ul.ul_support li {
        background: url("../img/contents/support_icon02.png") no-repeat scroll left 5px;
        background-size: 23px 21px;
        font-size: 20px;
        color: #111111;
        padding-left: 30px;
        line-height: 34px;
        letter-spacing: -1px;
        font-weight: bold; }
        #PageSupport #Main h4 + div.div_support ul.ul_support li + li {
          margin-top: 5px; }
        #PageSupport #Main h4 + div.div_support ul.ul_support li span {
          font-size: 14px; }

/*
  トップページ・サポート共通
  -------------------------------------*/
#Page.pageIndex #Main .support_number,
#PageShiga.pageIndex #Main .support_number,
#PageSupport.pageIndex #Main .support_number,
#PageSupport.pageStresscheck #Main .support_number,
#PageSupport.pageMedicaladvisor #Main .support_number {
  overflow: hidden;
  counter-reset: support_number 0; }
  #Page.pageIndex #Main .support_number div,
  #PageShiga.pageIndex #Main .support_number div,
  #PageSupport.pageIndex #Main .support_number div,
  #PageSupport.pageStresscheck #Main .support_number div,
  #PageSupport.pageMedicaladvisor #Main .support_number div {
    position: relative;
    float: left;
    width: 485px; }
    #Page.pageIndex #Main .support_number div > span.largeBox,
    #PageShiga.pageIndex #Main .support_number div > span.largeBox,
    #PageSupport.pageIndex #Main .support_number div > span.largeBox,
    #PageSupport.pageStresscheck #Main .support_number div > span.largeBox,
    #PageSupport.pageMedicaladvisor #Main .support_number div > span.largeBox {
      display: block;
      height: 160px;
      width: 485px;
      text-indent: -9999px;
      transform: perspective(1px) translateZ(0px);
      transition-duration: 0.3s;
      transition-property: color, background-color, opacity; }
      #Page.pageIndex #Main .support_number div > span.largeBox:hover,
      #PageShiga.pageIndex #Main .support_number div > span.largeBox:hover,
      #PageSupport.pageIndex #Main .support_number div > span.largeBox:hover,
      #PageSupport.pageStresscheck #Main .support_number div > span.largeBox:hover,
      #PageSupport.pageMedicaladvisor #Main .support_number div > span.largeBox:hover {
        opacity: .8; }
        #Page.pageIndex #Main .support_number div > span.largeBox:hover span,
        #PageShiga.pageIndex #Main .support_number div > span.largeBox:hover span,
        #PageSupport.pageIndex #Main .support_number div > span.largeBox:hover span,
        #PageSupport.pageStresscheck #Main .support_number div > span.largeBox:hover span,
        #PageSupport.pageMedicaladvisor #Main .support_number div > span.largeBox:hover span {
          background: url("../img/contents/support_icon01.png") no-repeat center bottom 8px;
          background-color: #339c79;
          background-size: 9px 26px; }
      #Page.pageIndex #Main .support_number div > span.largeBox span,
      #PageShiga.pageIndex #Main .support_number div > span.largeBox span,
      #PageSupport.pageIndex #Main .support_number div > span.largeBox span,
      #PageSupport.pageStresscheck #Main .support_number div > span.largeBox span,
      #PageSupport.pageMedicaladvisor #Main .support_number div > span.largeBox span {
        position: absolute;
        right: 10px;
        top: 40px;
        display: block;
        background-color: #339c79;
        width: 40px;
        line-height: 40px;
        height: 140px;
        padding-top: 15px;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-style: italic;
        font-weight: 400;
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        background: url("../img/contents/support_icon01.png") no-repeat center bottom 14px;
        background-size: 9px 26px;
        text-indent: 0;
        opacity: .95;
        transform: perspective(1px) translateZ(0px);
        transition-duration: 0.3s;
        transition-property: color, background-color, background, opacity; }
        #Page.pageIndex #Main .support_number div > span.largeBox span a,
        #PageShiga.pageIndex #Main .support_number div > span.largeBox span a,
        #PageSupport.pageIndex #Main .support_number div > span.largeBox span a,
        #PageSupport.pageStresscheck #Main .support_number div > span.largeBox span a,
        #PageSupport.pageMedicaladvisor #Main .support_number div > span.largeBox span a {
          color: #fff;
          text-decoration: none; }
    #Page.pageIndex #Main .support_number div dl,
    #PageShiga.pageIndex #Main .support_number div dl,
    #PageSupport.pageIndex #Main .support_number div dl,
    #PageSupport.pageStresscheck #Main .support_number div dl,
    #PageSupport.pageMedicaladvisor #Main .support_number div dl {
      padding-left: 4px; }
      #Page.pageIndex #Main .support_number div dl dt,
      #PageShiga.pageIndex #Main .support_number div dl dt,
      #PageSupport.pageIndex #Main .support_number div dl dt,
      #PageSupport.pageStresscheck #Main .support_number div dl dt,
      #PageSupport.pageMedicaladvisor #Main .support_number div dl dt {
        height: 56px;
        color: #064c76;
        font-size: 24px;
        position: relative;
        font-weight: bold;
        padding-left: 30px;
        padding-top: 30px;
        /*67から変更*/
        letter-spacing: -0.8px; }
        #Page.pageIndex #Main .support_number div dl dt:before,
        #PageShiga.pageIndex #Main .support_number div dl dt:before,
        #PageSupport.pageIndex #Main .support_number div dl dt:before,
        #PageSupport.pageStresscheck #Main .support_number div dl dt:before,
        #PageSupport.pageMedicaladvisor #Main .support_number div dl dt:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: -35px;
          /*0から変更*/
          left: 0;
          width: 31px;
          height: 88px;
          background: url("../img/contents/support_number_bg.png") no-repeat left top;
          background-size: 31px 88px;
          counter-increment: support_number 1;
          content: counter(support_number) " ";
          color: #fff;
          font-size: 18px;
          font-family: 'Open Sans', sans-serif;
          font-style: italic;
          font-weight: 600;
          padding-left: 9px;
          padding-top: 6px;
          z-index: 97;
          /*変更*/ }
      #Page.pageIndex #Main .support_number div dl dd,
      #PageShiga.pageIndex #Main .support_number div dl dd,
      #PageSupport.pageIndex #Main .support_number div dl dd,
      #PageSupport.pageStresscheck #Main .support_number div dl dd,
      #PageSupport.pageMedicaladvisor #Main .support_number div dl dd {
        color: #333;
        font-size: 18px;
        letter-spacing: -0.8px;
        line-height: 30px;
        margin-top: 14px; }
    #Page.pageIndex #Main .support_number div:nth-of-type(1) > span,
    #PageShiga.pageIndex #Main .support_number div:nth-of-type(1) > span,
    #PageSupport.pageIndex #Main .support_number div:nth-of-type(1) > span,
    #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(1) > span,
    #PageSupport.pageMedicaladvisor #Main .support_number div:nth-of-type(1) > span {
      background: transparent url("../img/contents/doctor_img01.jpg") no-repeat scroll left top/485px 160px; }
    #Page.pageIndex #Main .support_number div:nth-of-type(2),
    #PageShiga.pageIndex #Main .support_number div:nth-of-type(2),
    #PageSupport.pageIndex #Main .support_number div:nth-of-type(2),
    #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(2),
    #PageSupport.pageMedicaladvisor #Main .support_number div:nth-of-type(2) {
      margin-left: 30px; }
      #Page.pageIndex #Main .support_number div:nth-of-type(2) > span,
      #PageShiga.pageIndex #Main .support_number div:nth-of-type(2) > span,
      #PageSupport.pageIndex #Main .support_number div:nth-of-type(2) > span,
      #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(2) > span,
      #PageSupport.pageMedicaladvisor #Main .support_number div:nth-of-type(2) > span {
        background: transparent url("../img/contents/doctor_img02.jpg") no-repeat scroll left top/485px 160px; }

/*
  トップページ・サポートメニュー共通
  -------------------------------------*/
#Page.pageIndex #Container #Main #ContBox01.support_number dl dt,
#Page.pageIndex #Container #Main #ContBox06.support_number dl dt,
#Page.pageIndex #Container #Main #ContBox02 dl dt,
#PageShiga.pageIndex #Container #Main #ContBox01.support_number dl dt,
#PageShiga.pageIndex #Container #Main #ContBox06.support_number dl dt,
#PageShiga.pageIndex #Container #Main #ContBox02 dl dt,
#PageSupport.pageIndex #Container #Main #ContBox01.support_number dl dt,
#PageSupport.pageIndex #Container #Main #ContBox06.support_number dl dt,
#PageSupport.pageIndex #Container #Main #ContBox02 dl dt {
  height: auto;
  letter-spacing: 1px;
  padding-left: 60px;
  padding-top: 14px;
  font-size: 26px;
  font-weight: bold; }
  #Page.pageIndex #Container #Main #ContBox01.support_number dl dt:before,
  #Page.pageIndex #Container #Main #ContBox06.support_number dl dt:before,
  #Page.pageIndex #Container #Main #ContBox02 dl dt:before,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number dl dt:before,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number dl dt:before,
  #PageShiga.pageIndex #Container #Main #ContBox02 dl dt:before,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number dl dt:before,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number dl dt:before,
  #PageSupport.pageIndex #Container #Main #ContBox02 dl dt:before {
    background: none;
    counter-increment: support_numbers 1;
    content: counter(support_numbers, decimal-leading-zero) " ";
    font-size: 50px;
    color: #065d91;
    position: absolute;
    top: -7px;
    left: -8px;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-weight: 600;
    height: 88px;
    width: 31px;
    padding: 0;
    z-index: 1; }

#Page.pageIndex #Container #Main #ContBox01.support_number dl dd,
#Page.pageIndex #Container #Main #ContBox06.support_number dl dd,
#Page.pageIndex #Container #Main #ContBox02 dl dd,
#PageShiga.pageIndex #Container #Main #ContBox01.support_number dl dd,
#PageShiga.pageIndex #Container #Main #ContBox06.support_number dl dd,
#PageShiga.pageIndex #Container #Main #ContBox02 dl dd,
#PageSupport.pageIndex #Container #Main #ContBox01.support_number dl dd,
#PageSupport.pageIndex #Container #Main #ContBox06.support_number dl dd,
#PageSupport.pageIndex #Container #Main #ContBox02 dl dd {
  letter-spacing: -1px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number dl dd span,
  #Page.pageIndex #Container #Main #ContBox06.support_number dl dd span,
  #Page.pageIndex #Container #Main #ContBox02 dl dd span,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number dl dd span,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number dl dd span,
  #PageShiga.pageIndex #Container #Main #ContBox02 dl dd span,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number dl dd span,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number dl dd span,
  #PageSupport.pageIndex #Container #Main #ContBox02 dl dd span {
    font-size: 14px;
    color: #888; }

#Page.pageIndex #Container #Main #ContBox01.support_number,
#Page.pageIndex #Container #Main #ContBox06.support_number,
#PageShiga.pageIndex #Container #Main #ContBox01.support_number,
#PageShiga.pageIndex #Container #Main #ContBox06.support_number,
#PageSupport.pageIndex #Container #Main #ContBox01.support_number,
#PageSupport.pageIndex #Container #Main #ContBox06.support_number {
  counter-reset: support_numbers 0; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div > span,
  #Page.pageIndex #Container #Main #ContBox06.support_number div > span,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div > span,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div > span,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div > span,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div > span {
    height: 180px;
    position: relative;
    z-index: 2; }
    #Page.pageIndex #Container #Main #ContBox01.support_number div > span:hover span,
    #Page.pageIndex #Container #Main #ContBox06.support_number div > span:hover span,
    #PageShiga.pageIndex #Container #Main #ContBox01.support_number div > span:hover span,
    #PageShiga.pageIndex #Container #Main #ContBox06.support_number div > span:hover span,
    #PageSupport.pageIndex #Container #Main #ContBox01.support_number div > span:hover span,
    #PageSupport.pageIndex #Container #Main #ContBox06.support_number div > span:hover span {
      background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 10px center/26px 9px; }
    #Page.pageIndex #Container #Main #ContBox01.support_number div > span span,
    #Page.pageIndex #Container #Main #ContBox06.support_number div > span span,
    #PageShiga.pageIndex #Container #Main #ContBox01.support_number div > span span,
    #PageShiga.pageIndex #Container #Main #ContBox06.support_number div > span span,
    #PageSupport.pageIndex #Container #Main #ContBox01.support_number div > span span,
    #PageSupport.pageIndex #Container #Main #ContBox06.support_number div > span span {
      position: absolute;
      top: 140px;
      background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 14px center/26px 9px;
      width: 140px;
      height: 40px;
      writing-mode: horizontal-tb;
      padding: 0 0 0 15px; }
      #Page.pageIndex #Container #Main #ContBox01.support_number div > span span a,
      #Page.pageIndex #Container #Main #ContBox06.support_number div > span span a,
      #PageShiga.pageIndex #Container #Main #ContBox01.support_number div > span span a,
      #PageShiga.pageIndex #Container #Main #ContBox06.support_number div > span span a,
      #PageSupport.pageIndex #Container #Main #ContBox01.support_number div > span span a,
      #PageSupport.pageIndex #Container #Main #ContBox06.support_number div > span span a {
        color: #fff;
        text-decoration: none; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(1) > span,
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(1) > span,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(1) > span,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(1) > span,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(1) > span,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(1) > span {
    background: transparent url("../img/contents/index_img01.jpg") no-repeat scroll left top/485px 160px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(2) > span,
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(2) > span,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(2) > span,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(2) > span,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(2) > span,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(2) > span {
    background: transparent url("../img/contents/index_img02.jpg") no-repeat scroll left top/485px 160px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(3) > span,
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(3) > span,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(3) > span,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(3) > span,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(3) > span,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(3) > span {
    background: transparent url("../img/contents/index_img03.jpg") no-repeat scroll left top/485px 160px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4) > span,
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4) > span,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4) > span,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4) > span,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4) > span,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4) > span {
    background: transparent url("../img/contents/index_img04.jpg") no-repeat scroll left top/485px 160px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(2), #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4),
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(2),
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4),
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(2),
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4),
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(2),
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4),
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(2),
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4),
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(2),
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4) {
    margin-left: 30px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(3), #Page.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4),
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(3),
  #Page.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4),
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(3),
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4),
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(3),
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4),
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(3),
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div:nth-of-type(4),
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(3),
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div:nth-of-type(4) {
    margin-top: 45px; }
  #Page.pageIndex #Container #Main #ContBox01.support_number div dl dd,
  #Page.pageIndex #Container #Main #ContBox06.support_number div dl dd,
  #PageShiga.pageIndex #Container #Main #ContBox01.support_number div dl dd,
  #PageShiga.pageIndex #Container #Main #ContBox06.support_number div dl dd,
  #PageSupport.pageIndex #Container #Main #ContBox01.support_number div dl dd,
  #PageSupport.pageIndex #Container #Main #ContBox06.support_number div dl dd {
    margin-top: 9px; }

#Page.pageIndex #Container #Main #ContBox02,
#PageShiga.pageIndex #Container #Main #ContBox02,
#PageSupport.pageIndex #Container #Main #ContBox02 {
  margin-top: 73px;
  background-color: #f3f3f3;
  padding-top: 81px;
  padding-bottom: 90px;
  border-top: 4px solid #235471; }
  #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic,
  #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic,
  #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic {
    float: none;
    width: 1000px; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(1) > span,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(1) > span,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(1) > span {
      background: transparent url("../img/contents/index_img05.jpg") no-repeat scroll left top/315px 160px; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(2) > span,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(2) > span,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(2) > span {
      background: transparent url("../img/contents/index_img06.jpg") no-repeat scroll left top/315px 160px; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(3) > span,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(3) > span,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(3) > span {
      background: transparent url("../img/contents/index_img07.jpg") no-repeat scroll left top/315px 160px; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div:first-of-type dt:before,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div:first-of-type dt:before,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div:first-of-type dt:before {
      top: 0; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dt,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dt,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dt {
      font-size: 26px;
      line-height: 35px;
      padding-top: 7px; }
      #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dt:before,
      #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dt:before,
      #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dt:before {
        top: 8px;
        padding-top: 0; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dd,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dd,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div dl dd {
      margin-top: 10px; }
    #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(2) dl dt:before, #Page.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(3) dl dt:before,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(2) dl dt:before,
    #PageShiga.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(3) dl dt:before,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(2) dl dt:before,
    #PageSupport.pageIndex #Container #Main #ContBox02 > div.innerBasic div:nth-of-type(3) dl dt:before {
      padding-top: 0; }
  #Page.pageIndex #Container #Main #ContBox02 + h3 span.innerBasic,
  #PageShiga.pageIndex #Container #Main #ContBox02 + h3 span.innerBasic,
  #PageSupport.pageIndex #Container #Main #ContBox02 + h3 span.innerBasic {
    background: transparent url("../img/contents/index_img05.jpg") no-repeat scroll right bottom/395px 200px; }

/*
  サポートメニュー ストレスチェック共通 3列
  -------------------------------------*/
#Page.pageIndex #Main #ContBox02.support_number div div,
#PageShiga.pageIndex #Main #ContBox02.support_number div div,
#PageSupport.pageIndex #Main #ContBox02.support_number div div,
#PageSupport.pageStresscheck #Main .support_number div {
  width: 315px; }
  #Page.pageIndex #Main #ContBox02.support_number div div > span,
  #PageShiga.pageIndex #Main #ContBox02.support_number div div > span,
  #PageSupport.pageIndex #Main #ContBox02.support_number div div > span,
  #PageSupport.pageStresscheck #Main .support_number div > span {
    width: 315px !important;
    height: 180px !important; }
  #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(1) > span,
  #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(1) > span,
  #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(1) > span,
  #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(1) > span {
    background: transparent url("../img/contents/stress_img01.png") no-repeat scroll left top/315px 160px; }
  #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) > span,
  #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) > span,
  #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) > span,
  #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(2) > span {
    background: transparent url("../img/contents/stress_img02.png") no-repeat scroll left top/315px 160px; }
  #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) > span,
  #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) > span,
  #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) > span,
  #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(3) > span {
    background: transparent url("../img/contents/stress_img03.png") no-repeat scroll left top/315px 160px; }
  #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2), #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3),
  #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2),
  #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3),
  #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2),
  #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3),
  #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(2),
  #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(3) {
    margin-left: 27px; }
    #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) dl dt, #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) dl dt,
    #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) dl dt,
    #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) dl dt,
    #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) dl dt,
    #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) dl dt,
    #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(2) dl dt,
    #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(3) dl dt {
      line-height: 29px;
      padding-top: 0; }
      #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) dl dt:before, #Page.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) dl dt:before,
      #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) dl dt:before,
      #PageShiga.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) dl dt:before,
      #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(2) dl dt:before,
      #PageSupport.pageIndex #Main #ContBox02.support_number div div:nth-of-type(3) dl dt:before,
      #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(2) dl dt:before,
      #PageSupport.pageStresscheck #Main .support_number div:nth-of-type(3) dl dt:before {
        line-height: 16px; }

/*
  サポート共通
  -------------------------------------*/
#PageSupport div#MainImgInner h2 {
  font-weight: bold; }
  #PageSupport div#MainImgInner h2 span:nth-of-type(2) {
    padding-left: 0; }
    #PageSupport div#MainImgInner h2 span:nth-of-type(2) .h2number {
      background: transparent none repeat scroll 0 0;
      color: #065d91;
      display: inline-block;
      font-family: "Open Sans", sans-serif;
      font-size: 40px;
      font-style: italic;
      font-weight: 600;
      padding: 0;
      padding-right: 15px; }

/*
  サポートメニュー
  -------------------------------------*/
#PageSupport.pageIndex div#MainImgInner h2 {
  font-size: 28px;
  color: #111;
  line-height: 240px;
  padding-top: 0;
  background: url("../img/contents/index_icon01.png") no-repeat scroll left center;
  background-size: 42px 36px;
  padding-left: 60px; }

#PageSupport.pageIndex #Container #Main {
  counter-reset: h3_numbers 4; }
  #PageSupport.pageIndex #Container #Main h3 span.innerBasic {
    padding-top: 65px;
    line-height: 34px; }
    #PageSupport.pageIndex #Container #Main h3 span.innerBasic:before {
      color: #daf1ff;
      content: counter(h3_numbers, decimal-leading-zero) " ";
      counter-increment: h3_numbers 1;
      font-family: "Open Sans", sans-serif;
      font-size: 40px;
      font-style: italic;
      font-weight: 600; }
    #PageSupport.pageIndex #Container #Main h3 span.innerBasic span {
      display: block;
      font-size: 18px; }
  #PageSupport.pageIndex #Container #Main #ContBox03 .div_support div,
  #PageSupport.pageIndex #Container #Main #ContBox04 .div_support div,
  #PageSupport.pageIndex #Container #Main #ContBox05 .div_support div {
    width: 100%; }
  #PageSupport.pageIndex #Container #Main #ContBox03 + h3 span.innerBasic,
  #PageSupport.pageIndex #Container #Main #ContBox04 + h3 span.innerBasic,
  #PageSupport.pageIndex #Container #Main #ContBox05 + h3 span.innerBasic {
    padding-top: 80px; }
  #PageSupport.pageIndex #Container #Main #ContBox03 .ul_support li,
  #PageSupport.pageIndex #Container #Main #ContBox04 .ul_support li,
  #PageSupport.pageIndex #Container #Main #ContBox05 .ul_support li {
    font-weight: bold; }
  #PageSupport.pageIndex #Container #Main #ContBox03 ul li {
    font-weight: bold; }
  #PageSupport.pageIndex #Container #Main #ContBox03 .div_support {
    margin-bottom: 78px; }
    #PageSupport.pageIndex #Container #Main #ContBox03 .div_support div {
      padding: 33px 30px 32px; }
    #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(2), #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(3) {
      margin-bottom: 0; }
      #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(2) + p, #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(3) + p {
        color: #333333;
        margin-bottom: 72px;
        line-height: 23px; }
    #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(3) {
      background: transparent url("../img/contents/support_img01.jpg") no-repeat scroll left 34px top 28px/46px 286px; }
      #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(3) div {
        padding: 43px 30px 38px; }
        #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(3) div ul li {
          color: #185479;
          font-size: 18px;
          padding-left: 58px; }
          #PageSupport.pageIndex #Container #Main #ContBox03 .div_support:nth-of-type(3) div ul li + li {
            margin-top: 42px; }
  #PageSupport.pageIndex #Container #Main #ContBox03 + h3 span.innerBasic {
    background: transparent url("../img/contents/index_img06.jpg") no-repeat scroll right bottom/395px 200px; }
  #PageSupport.pageIndex #Container #Main #ContBox04 .div_support {
    margin-bottom: 100px; }
    #PageSupport.pageIndex #Container #Main #ContBox04 .div_support div {
      padding: 32px 30px 27px; }
  #PageSupport.pageIndex #Container #Main #ContBox04 + h3 span.innerBasic {
    background: transparent url("../img/contents/index_img07.jpg") no-repeat scroll right bottom/395px 200px; }
  #PageSupport.pageIndex #Container #Main #ContBox05 {
    border-bottom: 4px solid #235471; }
    #PageSupport.pageIndex #Container #Main #ContBox05 .div_support {
      margin-bottom: 100px; }
      #PageSupport.pageIndex #Container #Main #ContBox05 .div_support div {
        padding: 33px 30px 28px; }
  #PageSupport.pageIndex #Container #Main #ContBox06 {
    padding-top: 80px;
    padding-bottom: 100px; }

/*
  メンタルヘルス研修
  -------------------------------------*/
#PageSupport.pageMentalhealth h3 span.innerBasic {
  background: transparent url("../img/contents/training_img01.jpg") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageMentalhealth #Main #ContSubBox01 h4 {
  letter-spacing: 2px; }

#PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work {
  overflow: hidden; }
  #PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work li {
    float: left;
    width: 200px;
    height: 200px;
    border: 1px solid #95bacf;
    border-right: none;
    font-size: 20px;
    color: #065d91;
    text-align: center;
    line-height: 31px;
    padding-top: 113px;
    letter-spacing: -1px;
    font-weight: bold; }
    #PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work li:nth-of-type(1) {
      background: url("../img/contents/training_icon01.png") no-repeat scroll center top 28px;
      background-size: 65px 65px; }
    #PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work li:nth-of-type(2) {
      background: url("../img/contents/training_icon02.png") no-repeat scroll center top 38px;
      background-size: 66px 49px; }
    #PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work li:nth-of-type(3) {
      background: url("../img/contents/training_icon03.png") no-repeat scroll center top 39px;
      background-size: 47px 47px; }
    #PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work li:nth-of-type(4) {
      background: url("../img/contents/training_icon04.png") no-repeat scroll center top 31px;
      background-size: 48px 60px; }
    #PageSupport.pageMentalhealth #Main #ContSubBox01 ul.ul_work li:nth-of-type(5) {
      background: url("../img/contents/training_icon05.png") no-repeat scroll center top 36px;
      background-size: 64px 54px;
      border-right: 1px solid #95bacf; }

#PageSupport.pageMentalhealth #Main #ContSubBox02 {
  margin-top: 79px; }
  #PageSupport.pageMentalhealth #Main #ContSubBox02 p.self_care,
  #PageSupport.pageMentalhealth #Main #ContSubBox02 p.line_care {
    margin-bottom: 21px;
    display: block;
    width: 121px;
    height: 31px;
    line-height: 31px;
    background-color: #185479;
    border-radius: 15px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    font-weight: bold; }
  #PageSupport.pageMentalhealth #Main #ContSubBox02 p.line_care {
    background-color: #22906c; }

#PageSupport.pageMentalhealth #Main #ContSubBox03 {
  margin-top: 70px;
  margin-bottom: 113px; }
  #PageSupport.pageMentalhealth #Main #ContSubBox03 h4 + div {
    border: 1px solid #95bacf;
    height: 349px;
    text-align: center;
    padding-top: 30px; }

/*
  ヒューマンエラー
  -------------------------------------*/
#PageSupport.pageHumanerror #Main h3 span.innerBasic {
  background: transparent url("../img/contents/human_img01.png") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageHumanerror #Main .h3note {
  margin-bottom: 21px; }

#PageSupport.pageHumanerror #Main .h3note + div {
  border: 1px solid #95bacf;
  padding: 34px 38px; }
  #PageSupport.pageHumanerror #Main .h3note + div p {
    font-size: 20px;
    color: #065d91;
    letter-spacing: -1.3px;
    font-weight: bold; }

#PageSupport.pageHumanerror #Main h4 {
  margin-top: 80px; }
  #PageSupport.pageHumanerror #Main h4 span {
    font-size: 14px;
    letter-spacing: 0; }

#PageSupport.pageHumanerror #Main h4 + div.div_support div {
  padding: 31px 30px 26px; }

#PageSupport.pageHumanerror #Main #ContSubBox02 {
  margin-bottom: 56px; }
  #PageSupport.pageHumanerror #Main #ContSubBox02 h4 + div {
    position: relative;
    top: -88px; }

/*
  ストレスチェック  産業医・カウンセラー契約 見出し共通
  -------------------------------------*/
#PageSupport.pageStresscheck #Main,
#PageSupport.pageMedicaladvisor #Main {
  counter-reset: h3_number 0; }
  #PageSupport.pageStresscheck #Main > h3 > span,
  #PageSupport.pageMedicaladvisor #Main > h3 > span {
    position: relative; }
    #PageSupport.pageStresscheck #Main > h3 > span:before,
    #PageSupport.pageMedicaladvisor #Main > h3 > span:before {
      border: 1px solid #b2cedf;
      border-radius: 50%;
      color: #b2cedf;
      content: "";
      font-size: 28px;
      height: 50px;
      margin-right: 20px;
      padding: 5px 10px 5px 13px;
      width: 50px;
      font-family: 'Open Sans', sans-serif;
      font-style: italic;
      font-weight: 600;
      counter-increment: h3_number 1;
      content: counter(h3_number) " "; }

/*
  ストレスチェック
  -------------------------------------*/
#PageSupport.pageStresscheck #Main #ContBox01 + h3 {
  margin-top: 75px; }
  #PageSupport.pageStresscheck #Main #ContBox01 + h3 span.innerBasic {
    background: transparent url("../img/contents/stress_img01.png") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageStresscheck #Main #ContBox02 + h3 {
  margin-top: 75px; }
  #PageSupport.pageStresscheck #Main #ContBox02 + h3 span.innerBasic {
    background: transparent url("../img/contents/stress_img02.png") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageStresscheck #Main #ContBox03 + h3 {
  margin-top: 75px; }
  #PageSupport.pageStresscheck #Main #ContBox03 + h3 span.innerBasic {
    background: transparent url("../img/contents/stress_img03.png") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageStresscheck #Main #ContBox01 div {
  width: 315px; }
  #PageSupport.pageStresscheck #Main #ContBox01 div > span.largeBox {
    position: relative;
    z-index: 10;
    width: 315px !important;
    height: 180px !important; }
    #PageSupport.pageStresscheck #Main #ContBox01 div > span.largeBox span {
      z-index: 100; }
  #PageSupport.pageStresscheck #Main #ContBox01 div dl {
    z-index: 15; }

#PageSupport.pageStresscheck #Main #ContBox02 {
  overflow: hidden; }
  #PageSupport.pageStresscheck #Main #ContBox02 + h3 {
    margin-top: 102px; }
  #PageSupport.pageStresscheck #Main #ContBox02 .div_support div:nth-of-type(1) {
    width: 794px;
    padding: 28px 30px 27px; }
  #PageSupport.pageStresscheck #Main #ContBox02 .div_support div:nth-of-type(2) {
    width: 204px; }
    #PageSupport.pageStresscheck #Main #ContBox02 .div_support div:nth-of-type(2) p {
      font-size: 18px;
      color: #065d91;
      letter-spacing: -1px;
      font-weight: bold; }
      #PageSupport.pageStresscheck #Main #ContBox02 .div_support div:nth-of-type(2) p span {
        margin-top: 12px;
        display: block;
        font-size: 14px;
        color: #888888;
        line-height: 23px; }
  #PageSupport.pageStresscheck #Main #ContBox02 .div_support + h4 {
    margin-top: 71px; }
    #PageSupport.pageStresscheck #Main #ContBox02 .div_support + h4 + .div_support div {
      display: block;
      width: 100%; }
      #PageSupport.pageStresscheck #Main #ContBox02 .div_support + h4 + .div_support div:nth-of-type(1) {
        text-align: center; }
      #PageSupport.pageStresscheck #Main #ContBox02 .div_support + h4 + .div_support div:nth-of-type(2) {
        border-left: none;
        border-top: 1px solid #95bacf;
        padding: 30px 30px 32px 81px;
        background: transparent url("../img/contents/stress_img05.png") no-repeat scroll left 41px top 29px/32px 461px; }
        #PageSupport.pageStresscheck #Main #ContBox02 .div_support + h4 + .div_support div:nth-of-type(2) ul li {
          color: #333;
          font-size: 18px;
          line-height: 31px; }
          #PageSupport.pageStresscheck #Main #ContBox02 .div_support + h4 + .div_support div:nth-of-type(2) ul li + li {
            margin-top: 9px; }
  #PageSupport.pageStresscheck #Main #ContBox02 .ul_support span {
    font-size: 14px;
    color: #888888; }

#PageSupport.pageStresscheck #Main #ContBox03 .div_support div {
  display: block;
  width: 100%; }
  #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) {
    padding: 33px 30px 12px; }
    #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) .ul_support + ul {
      margin-top: 28px; }
      #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) .ul_support + ul li {
        display: block;
        height: 149px;
        font-size: 18px;
        color: #333;
        padding-top: 60px;
        padding-left: 109px;
        line-height: 34px;
        letter-spacing: -1px; }
        #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) .ul_support + ul li:nth-of-type(1) {
          background: transparent url("../img/contents/stress_img06.jpg") no-repeat scroll left top/490px 149px; }
          #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) .ul_support + ul li:nth-of-type(1) span {
            display: block;
            font-size: 14px;
            color: #888; }
        #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) .ul_support + ul li:nth-of-type(2) {
          margin-top: 7px;
          background: transparent url("../img/contents/stress_img07.jpg") no-repeat scroll left top/490px 149px; }
        #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(1) .ul_support + ul li:nth-of-type(3) {
          margin-top: 11px;
          background: transparent url("../img/contents/stress_img08.jpg") no-repeat scroll left top/490px 149px; }
  #PageSupport.pageStresscheck #Main #ContBox03 .div_support div:nth-of-type(2) {
    padding: 34px 30px;
    border-left: none;
    border-top: 1px solid #95bacf; }

#PageSupport.pageStresscheck #Main #ContBox04 {
  margin-bottom: 119px; }
  #PageSupport.pageStresscheck #Main #ContBox04 .div_support div {
    display: block;
    width: 100%; }
    #PageSupport.pageStresscheck #Main #ContBox04 .div_support div:nth-of-type(1) {
      padding: 34px 30px; }
    #PageSupport.pageStresscheck #Main #ContBox04 .div_support div:nth-of-type(2) {
      padding: 39px 30px 26px;
      border-left: none;
      border-top: 1px solid #95bacf; }
  #PageSupport.pageStresscheck #Main #ContBox04 p.h4_title {
    margin-bottom: 4px;
    letter-spacing: -2px; }
    #PageSupport.pageStresscheck #Main #ContBox04 p.h4_title + p span {
      font-size: 14px;
      color: #888; }

#PageSupport.pageStresscheck #Main h3#Stress_seat {
  margin-top: 100px; }

#PageSupport.pageStresscheck #Main p.h4_title {
  font-size: 20px;
  color: #065d91;
  letter-spacing: -1px;
  margin-bottom: 13px;
  font-weight: bold; }
  #PageSupport.pageStresscheck #Main p.h4_title + p {
    line-height: 33px;
    letter-spacing: -1px; }

/*
  産業医・カウンセラー契約
  -------------------------------------*/
#PageSupport.pageMedicaladvisor #Main #ContBox01.support_number .largeBox {
  height: 180px; }

#PageSupport.pageMedicaladvisor #Main #ContBox01.support_number div dl {
  top: 0; }
  #PageSupport.pageMedicaladvisor #Main #ContBox01.support_number div dl dt:before {
    top: -36px; }
  #PageSupport.pageMedicaladvisor #Main #ContBox01.support_number div dl dd {
    margin-top: 11px; }

#PageSupport.pageMedicaladvisor #Main #ContBox01 div dl {
  padding-left: 4px;
  position: relative;
  top: -15px; }

#PageSupport.pageMedicaladvisor #Main #ContBox01 + h3 {
  margin-top: 93px; }
  #PageSupport.pageMedicaladvisor #Main #ContBox01 + h3 span.innerBasic {
    background: transparent url("../img/contents/doctor_img03.png") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageMedicaladvisor #Main #ContBox02 h4 + div.div_support,
#PageSupport.pageMedicaladvisor #Main #ContBox03 h4 + div.div_support {
  border: 1px solid #95bacf;
  overflow: hidden; }
  #PageSupport.pageMedicaladvisor #Main #ContBox02 h4 + div.div_support div,
  #PageSupport.pageMedicaladvisor #Main #ContBox03 h4 + div.div_support div {
    display: block;
    width: 100%;
    padding: 33px 30px; }
    #PageSupport.pageMedicaladvisor #Main #ContBox02 h4 + div.div_support div:nth-of-type(2),
    #PageSupport.pageMedicaladvisor #Main #ContBox03 h4 + div.div_support div:nth-of-type(2) {
      border-top: 1px solid #95bacf;
      border-left: none; }
      #PageSupport.pageMedicaladvisor #Main #ContBox02 h4 + div.div_support div:nth-of-type(2) p,
      #PageSupport.pageMedicaladvisor #Main #ContBox03 h4 + div.div_support div:nth-of-type(2) p {
        line-height: 33px; }

#PageSupport.pageMedicaladvisor #Main #ContBox02 + h3 {
  margin-top: 100px; }
  #PageSupport.pageMedicaladvisor #Main #ContBox02 + h3 span.innerBasic {
    background: transparent url("../img/contents/doctor_img04.png") no-repeat scroll right bottom/395px 200px; }

#PageSupport.pageMedicaladvisor #Main #ContBox03 {
  margin-bottom: 146px; }

/*
 サポート実績共通 見出し
  -------------------------------------*/
#PageCase.pageCategory #Main #ContBox01 h3,
#PageCase.pageMonthly #Main #ContBox01 h3 {
  color: #111;
  font-size: 22px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 66px; }

/*
  カテゴリアーカイブ（サポート実績）
  -------------------------------------*/
#PageCase.pageCategory #Main #ContBox01 h3 {
  color: #111;
  font-size: 22px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 66px; }

/*
  月別アーカイブ（サポート実績）
  -------------------------------------*/
#PageCase.pageMonthly #Main #ContBox01 div.entryBlock:last-of-type {
  border-bottom: none; }

/*
  記事詳細（サポート実績）
  -------------------------------------*/
#PageCase.pageEntry #Main #ContBox01 #ContSubBox01 {
  margin-bottom: 80px; }
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 div.entryBlock {
    padding-top: 0;
    border-top: none;
    border-bottom: 5px solid #235471; }
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 div.entryBlock:hover {
      opacity: 1; }
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand,
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step,
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice {
    margin-top: 30px;
    padding: 40px 35px 32px; }
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand .div_demand_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand .div_step_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand .div_voice_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step .div_demand_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step .div_step_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step .div_voice_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice .div_demand_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice .div_step_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice .div_voice_title {
      display: block;
      height: 31px;
      line-height: 31px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      margin-bottom: 28px;
      border-radius: 15px; }
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand p + p + p,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step p + p + p,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice p + p + p {
      margin-top: 31px; }
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand .div_demand_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step .div_demand_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice .div_demand_title {
      width: 97px;
      background-color: #185479; }
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand .div_step_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step .div_step_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice .div_step_title {
      width: 83px;
      background-color: #22906c; }
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand .div_voice_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step .div_voice_title,
    #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice .div_voice_title {
      width: 95px;
      background-color: #b37c36; }
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_demand {
    border: 1px solid #95bacf; }
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_step {
    border: 1px solid #95c7b7; }
  #PageCase.pageEntry #Main #ContBox01 #ContSubBox01 .div_voice {
    border: 1px solid #d1ac7d; }

/*
  組織案内
  -------------------------------------*/
#PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 {
  margin-top: 57px; }
  #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 img + p {
    text-align: center;
    font-size: 16px;
    color: #333;
    padding-top: 9px;
    letter-spacing: -1px; }
  #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 img + p + div {
    width: 688px;
    margin: 26px auto 36px;
    padding-top: 0;
    padding-bottom: 8px;
    background: url("../img/contents/soshiki_img02.png") no-repeat scroll left top, url("../img/contents/soshiki_img03.png") no-repeat scroll right bottom; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 img + p + div p {
      text-align: center;
      font-size: 22px;
      line-height: 40px;
      color: #111111;
      font-weight: bold; }
  #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 div.greeting {
    border: 1px solid #95bacf;
    padding: 34px 39px 30px; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 div.greeting p {
      font-size: 18px;
      color: #333333;
      letter-spacing: -1px;
      line-height: 33px; }
      #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 div.greeting p.greeting_title {
        font-size: 20px;
        font-weight: bold;
        color: #065d91; }
        #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 div.greeting p.greeting_title span {
          font-size: 14px;
          margin-left: 18px; }
        #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 div.greeting p.greeting_title + p {
          margin-top: 16px; }
      #PageClinic.pageIndex #Main #ContBox01 #ContSubBox01 div.greeting p + p {
        margin-top: 21px; }

#PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 {
  margin-top: 78px;
  margin-bottom: 124px; }
  #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl {
    border-top: 1px solid #dadada;
    display: table;
    padding: 14px 0 15px;
    width: 100%;
    color: #000;
    font-size: 18px;
    line-height: 30px; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl:nth-of-type(3) dd img {
      position: relative;
      top: -7px; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl:nth-of-type(3) a {
      color: #555;
      text-decoration: none; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl:nth-of-type(4) dd img {
      position: relative;
      top: -6px; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl dt {
      display: table-cell;
      width: 199px;
      font-weight: bold; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl dd {
      color: #555;
      display: table-cell; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl.logo dd a {
      display: block;
      background: url("../img/contents/soshiki_img04.png") no-repeat scroll left top;
      background-size: 200px 55px;
      width: 200px;
      height: 55px;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap; }
    #PageClinic.pageIndex #Main #ContBox01 #ContSubBox02 div dl:last-child {
      border-bottom: 1px solid #dadada; }

/*
  お問い合わせ
  -------------------------------------*/
#PageContact.pageIndex #Main #ContBox01 #ContSubBox02 {
  background-color: #f3f3f3;
  border-top: 4px solid #235471;
  margin-top: 20px;
  padding-bottom: 79px;
  padding-top: 60px; }
  #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h4 {
    color: #111;
    margin-top: 18px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h4:after {
      margin-bottom: 32px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h4 + p {
      line-height: 35px;
      letter-spacing: -1.5px;
      margin-bottom: 32px; }
  #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div {
    padding: 37px 40px 34px;
    background-color: #fff;
    margin-bottom: 10px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div p,
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div ul li {
      font-size: 16px;
      color: #333;
      line-height: 30px;
      letter-spacing: -1px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div ul {
      margin-top: 14px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div p + p {
      margin-top: 15px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div p.policy_title {
      font-size: 18px;
      color: #111;
      margin-bottom: 17px;
      font-weight: bold; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div p.tel img {
      vertical-align: middle; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 div.innerBasic div p.tel a {
      color: #333 !important;
      text-decoration: none; }

/*
  お問い合わせform共通
  -------------------------------------*/
#PageContact.pageIndex #Main h3.h4,
#PageContact.pageConfirm #Main h3.h4 {
  margin-top: 58px; }

#PageContact.pageIndex #Main #form1,
#PageContact.pageConfirm #Main #form1 {
  margin-top: 34px; }
  #PageContact.pageIndex #Main #form1 dl:first-of-type,
  #PageContact.pageConfirm #Main #form1 dl:first-of-type {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #dadada;
    padding-bottom: 29px; }
    #PageContact.pageIndex #Main #form1 dl:first-of-type dt span,
    #PageContact.pageConfirm #Main #form1 dl:first-of-type dt span {
      margin-top: 20px;
      display: block;
      color: #666;
      font-size: 14px;
      font-weight: normal; }
    #PageContact.pageIndex #Main #form1 dl:first-of-type dd:nth-of-type(2),
    #PageContact.pageConfirm #Main #form1 dl:first-of-type dd:nth-of-type(2) {
      padding-left: 10px; }
  #PageContact.pageIndex #Main #form1 dl + dl,
  #PageContact.pageConfirm #Main #form1 dl + dl {
    width: 100%;
    border-bottom: 1px solid #dadada; }
  #PageContact.pageIndex #Main #form1 dl dt,
  #PageContact.pageConfirm #Main #form1 dl dt {
    display: table-cell;
    width: 215px;
    padding-top: 29px;
    padding-bottom: 30px;
    color: #333;
    font-size: 18px;
    vertical-align: top;
    font-weight: bold;
    line-height: 20px; }
  #PageContact.pageIndex #Main #form1 dl dd,
  #PageContact.pageConfirm #Main #form1 dl dd {
    display: table-cell;
    color: #333;
    font-size: 18px;
    line-height: 20px; }
    #PageContact.pageIndex #Main #form1 dl dd div.checker,
    #PageContact.pageConfirm #Main #form1 dl dd div.checker {
      margin-top: 10px;
      margin-left: 11px; }
      #PageContact.pageIndex #Main #form1 dl dd div.checker span,
      #PageContact.pageConfirm #Main #form1 dl dd div.checker span {
        display: inline-block;
        width: 23px;
        height: 23px;
        line-height: 24px;
        background: transparent url("../img/contents/mail_img01.jpg") no-repeat scroll left center; }
        #PageContact.pageIndex #Main #form1 dl dd div.checker span.checked,
        #PageContact.pageConfirm #Main #form1 dl dd div.checker span.checked {
          background: transparent url("../img/contents/mail_img02.jpg") no-repeat scroll left center; }
      #PageContact.pageIndex #Main #form1 dl dd div.checker input,
      #PageContact.pageConfirm #Main #form1 dl dd div.checker input {
        height: 25px;
        width: 25px; }
      #PageContact.pageIndex #Main #form1 dl dd div.checker + label,
      #PageContact.pageConfirm #Main #form1 dl dd div.checker + label {
        vertical-align: bottom; }
  #PageContact.pageIndex #Main #form1 dl dd:nth-of-type(1),
  #PageContact.pageConfirm #Main #form1 dl dd:nth-of-type(1) {
    width: 63px;
    padding-top: 26px;
    padding-left: 0;
    text-align: center; }
    #PageContact.pageIndex #Main #form1 dl dd:nth-of-type(1) > span,
    #PageContact.pageConfirm #Main #form1 dl dd:nth-of-type(1) > span {
      display: inline-block;
      width: 51px;
      height: 27px;
      line-height: 27px;
      background-color: #446e88;
      border-radius: 13px;
      color: #fff;
      font-size: 14px;
      text-align: center; }
    #PageContact.pageIndex #Main #form1 dl dd:nth-of-type(1) .required,
    #PageContact.pageConfirm #Main #form1 dl dd:nth-of-type(1) .required {
      background: #dc896c none repeat scroll 0 0; }
  #PageContact.pageIndex #Main #form1 dl dd:nth-of-type(3),
  #PageContact.pageConfirm #Main #form1 dl dd:nth-of-type(3) {
    vertical-align: middle; }
  #PageContact.pageIndex #Main #form1 dl input[type="text"],
  #PageContact.pageConfirm #Main #form1 dl input[type="text"] {
    width: 420px;
    height: 45px;
    margin-left: 20px;
    padding-left: 17px;
    border: 1px solid #95bacf;
    background-color: #f4fafd;
    color: #333;
    font-size: 18px;
    border-radius: 0 !important; }
    #PageContact.pageIndex #Main #form1 dl input[type="text"]:focus,
    #PageContact.pageConfirm #Main #form1 dl input[type="text"]:focus {
      border: 1px solid #2073a4;
      background-color: #fff; }
  #PageContact.pageIndex #Main #form1 dl div.selector,
  #PageContact.pageConfirm #Main #form1 dl div.selector {
    overflow: visible;
    width: 260px !important;
    height: 45px !important;
    margin-left: 20px; }
    #PageContact.pageIndex #Main #form1 dl div.selector span,
    #PageContact.pageConfirm #Main #form1 dl div.selector span {
      display: inline-block;
      height: 45px;
      line-height: 45px;
      vertical-align: middle;
      width: 260px !important;
      background-image: url(../img/contents/form_select3a.png), url(../img/contents/form_select3.png);
      background-repeat: no-repeat, no-repeat;
      background-position: 94% center, left center;
      background-size: 12px 8px, 100% 45px;
      padding-left: 18px; }
    #PageContact.pageIndex #Main #form1 dl div.selector > .selector,
    #PageContact.pageConfirm #Main #form1 dl div.selector > .selector {
      background: none;
      margin-left: 0; }
    #PageContact.pageIndex #Main #form1 dl div.selector select,
    #PageContact.pageConfirm #Main #form1 dl div.selector select {
      height: 45px;
      cursor: pointer;
      display: inline-block;
      width: 260px !important;
      vertical-align: middle;
      line-height: 46px;
      color: #999; }
      #PageContact.pageIndex #Main #form1 dl div.selector select option,
      #PageContact.pageConfirm #Main #form1 dl div.selector select option {
        color: #333;
        padding-left: 18px; }
    #PageContact.pageIndex #Main #form1 dl div.selector .example2,
    #PageContact.pageConfirm #Main #form1 dl div.selector .example2 {
      color: blue !important; }
  #PageContact.pageIndex #Main #form1 dl textarea,
  #PageContact.pageConfirm #Main #form1 dl textarea {
    width: 700px;
    height: 195px;
    margin-top: 18px;
    margin-bottom: 16px;
    margin-left: 20px;
    padding-top: 10px;
    padding-left: 17px;
    border: 1px solid #95bacf;
    background-color: #f4fafd;
    color: #333;
    resize: none;
    border-radius: 0 !important; }
  #PageContact.pageIndex #Main #form1 .postMark,
  #PageContact.pageConfirm #Main #form1 .postMark {
    position: relative;
    left: 20px; }
  #PageContact.pageIndex #Main #form1 dl #text3,
  #PageContact.pageConfirm #Main #form1 dl #text3 {
    margin-left: 22px;
    width: 150px; }
  #PageContact.pageIndex #Main #form1 dl #text4,
  #PageContact.pageConfirm #Main #form1 dl #text4 {
    width: 514px;
    margin-left: 7px !important; }
  #PageContact.pageIndex #Main #form1 dl #text5,
  #PageContact.pageIndex #Main #form1 dl #text6,
  #PageContact.pageIndex #Main #form1 dl #text7,
  #PageContact.pageConfirm #Main #form1 dl #text5,
  #PageContact.pageConfirm #Main #form1 dl #text6,
  #PageContact.pageConfirm #Main #form1 dl #text7 {
    width: 320px; }
  #PageContact.pageIndex #Main #form1 dl #text8,
  #PageContact.pageIndex #Main #form1 dl #text9,
  #PageContact.pageConfirm #Main #form1 dl #text8,
  #PageContact.pageConfirm #Main #form1 dl #text9 {
    width: 220px; }
  #PageContact.pageIndex #Main #form1 dl label span,
  #PageContact.pageConfirm #Main #form1 dl label span {
    font-size: 14px;
    color: #666666;
    vertical-align: bottom; }
  #PageContact.pageIndex #Main #form1 textarea:focus,
  #PageContact.pageConfirm #Main #form1 textarea:focus {
    border: 1px solid #2073a4;
    background-color: #fff; }
  #PageContact.pageIndex #Main #form1 li.confirm,
  #PageContact.pageConfirm #Main #form1 li.confirm {
    display: block;
    width: 1000px;
    margin: 0 auto;
    text-align: center; }
  #PageContact.pageIndex #Main #form1 ul.contact-form1 li,
  #PageContact.pageConfirm #Main #form1 ul.contact-form1 li {
    display: table-cell;
    height: 50px;
    vertical-align: middle; }
  #PageContact.pageIndex #Main #form1 li.reset input[type="button"],
  #PageContact.pageConfirm #Main #form1 li.reset input[type="button"] {
    color: #666;
    font-size: 13px;
    text-decoration: underline; }
  #PageContact.pageIndex #Main #form1 #image-btn,
  #PageContact.pageConfirm #Main #form1 #image-btn {
    background: #4aa07a url("../img/contents/contact_img01.png") no-repeat scroll right 19px center;
    display: block;
    width: 347px;
    height: 78px;
    margin: 60px auto 80px;
    color: #fff;
    font-size: 18px;
    line-height: 78px;
    font-weight: bold;
    transform: perspective(1px) translateZ(0px);
    transition-duration: 0.3s;
    transition-property: color, background-color, background, opacity; }
    #PageContact.pageIndex #Main #form1 #image-btn:hover,
    #PageContact.pageConfirm #Main #form1 #image-btn:hover {
      opacity: .8;
      background: #4aa07a url("../img/contents/contact_img01.png") no-repeat scroll right 15px center; }

#PageContact.pageIndex #Main #form1 dl + dl,
#PageContact.pageConfirm #Container #form1 #PageContact.pageIndex #Main #form1 dl,
#PageContact.pageConfirm #Container #form1 dl {
  display: table; }

/* placeholder */
#PageContact.pageIndex #Main #form1 dl input[type="text"]::-webkit-input-placeholder {
  font-size: 18px;
  color: #999; }

#PageContact.pageIndex #Main #form1 dl textarea::-webkit-input-placeholder {
  font-size: 18px;
  color: #999;
  line-height: 33px; }

#PageContact.pageIndex #Main #form1 dl input[type="text"]:-ms-input-placeholder {
  font-size: 18px;
  color: #999; }

#PageContact.pageIndex #Main #form1 dl textarea:-ms-input-placeholder {
  font-size: 18px;
  color: #999;
  line-height: 33px; }

#PageContact.pageIndex #Main #form1 dl input[type="text"]:-moz-placeholder {
  font-size: 18px;
  color: #999; }

#PageContact.pageIndex #Main #form1 dl input[type="text"]::-moz-placeholder {
  font-size: 18px;
  color: #999;
  opacity: 1; }

#PageContact.pageIndex #Main #form1 dl textarea:-moz-placeholder {
  font-size: 18px;
  color: #999;
  line-height: 33px; }

/* checkbox */
div#uniform-text1_1 {
  margin-top: 0 !important; }
  div#uniform-text1_1 + label {
    margin-top: 0 !important; }

dl.contact dd:nth-of-type(1) {
  vertical-align: top;
  position: relative;
  top: -3px; }

/*
  お問い合わせ 確認
  -------------------------------------*/
#PageContact.pageConfirm #Main #ContBox01 dl:first-of-type {
  padding-bottom: 0; }

#PageContact.pageConfirm #Main #ContBox01 dd {
  padding-left: 18px;
  padding-bottom: 0; }

#PageContact.pageConfirm #Main #ContBox01 dl:last-of-type {
  padding-bottom: 0; }
  #PageContact.pageConfirm #Main #ContBox01 dl:last-of-type dd {
    padding-top: 28px;
    padding-bottom: 28px;
    line-height: 29px; }

#PageContact.pageConfirm #Main #ContBox01 .postMark {
  position: relative;
  left: 0 !important; }

#PageContact.pageConfirm #Main #ContBox01 li.reset input[type="button"] {
  display: block;
  height: 35px;
  margin: 60px auto 80px;
  color: #1c7c5c;
  font-size: 14px;
  line-height: 35px; }
  #PageContact.pageConfirm #Main #ContBox01 li.reset input[type="button"]:hover {
    text-decoration: none; }

/*
  お問い合わせ 完了
  -------------------------------------*/
#PageContact.pageThanks #Main #ContBox01 {
  margin-bottom: 101px; }
  #PageContact.pageThanks #Main #ContBox01 h3.h4 {
    margin-top: 60px; }
    #PageContact.pageThanks #Main #ContBox01 h3.h4:after {
      margin-bottom: 34px; }
  #PageContact.pageThanks #Main #ContBox01 p {
    line-height: 33px; }
  #PageContact.pageThanks #Main #ContBox01 p + p {
    margin-top: 19px; }

/*
  アイコン共通
  -------------------------------------*/
#MainImg + #Container #Main p.contactBack {
  border: 1px solid #289772;
  margin-left: auto;
  width: 149px;
  text-align: center; }
  #MainImg + #Container #Main p.contactBack a {
    display: block;
    height: 48px;
    line-height: 50px;
    text-decoration: none;
    font-size: 16px; }
    #MainImg + #Container #Main p.contactBack a:hover {
      background-color: #339c79;
      color: #fff; }

#MainImg + #Container #Main .s_more {
  background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 14px center/26px 9px;
  height: 40px;
  padding: 0 0 0 15px;
  top: 140px;
  width: 140px;
  margin-left: auto;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
  text-indent: 0;
  transform: perspective(1px) translateZ(0px);
  transition-duration: 0.3s;
  transition-property: color, background-color, background, opacity; }
  #MainImg + #Container #Main .s_more a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none; }
  #MainImg + #Container #Main .s_more:hover {
    opacity: .8;
    background: #339c79 url("../img/contents/index_icon04.png") no-repeat scroll right 10px center/26px 9px; }
